import React, {useEffect, useState} from "react";
import {IAuthState} from "../../store/auth/auth";
import {IParamsTypes} from "../../helper/queryParam/types";
import {ICustomersState} from "../../store/enty/customers";
import people from '../../assets/images/card_people.jpg'
import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	makeStyles,
	Typography
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import {startCase} from 'lodash';

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},
});

interface PropsFromState {
	authState: IAuthState,
	customersState: ICustomersState,
}

interface PropsFromDispatch {
	actCustomersGet: (param: IParamsTypes) => void,
}

type IProps = PropsFromState & PropsFromDispatch;

export function CustomersCard(props: IProps) {
	const classes = useStyles();
	const [time, setTime] = useState(new Date());
	const dataOptions = {
		weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
	}

	const { actCustomersGet, customersState } = props;

	useEffect(() => {
		setInterval(() => {
			actCustomersGet({});
			setTime(new Date());
		}, 900000) // 60000
	}, []);

	return (
		<Card elevation={10} square={false}
		      color={'#d4c087'}
		>
			<CardActionArea >
				<CardMedia
				           className={classes.media}
				           image={people}
				           title="Clienti Registrati"
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" align={'center'} component="h2">
						Clienti registrati
					</Typography>
					<Typography variant="h5" color="textPrimary" align={'center'} component="p">
						{customersState && customersState.total ?
							customersState.total :
							0
						}
					</Typography>
					<Typography style={{marginTop: '7px'}} variant="body2" color="textSecondary" component="p">
						{startCase(time.toLocaleDateString('it-IT', dataOptions))}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button size="small" color="primary"
				        startIcon={<FaceIcon />}
				>
					Apri
				</Button>
			</CardActions>
		</Card>
	)
}
