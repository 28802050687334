import {IUserDoc} from "../user";
import {IRoleDoc} from "../role";


export interface IAuthState {
	isAuthenticated?: 	boolean,
	isFetching?:				boolean,
	isInitializing?: 	  boolean,
	user?: 						  IUserDoc | null,
	role?:              IRoleDoc | null,
}

// Action payload with type
export interface IAuthAction {
	type: EAuthActionTypes,
	payload: {
		isAuthenticated?: 	boolean,
		isFetching?:				boolean,
		isInitializing?: 	  boolean,
		user?: 						  IUserDoc | null,
		role?:              IRoleDoc | null,
	},
}

// Type enum
export enum EAuthActionTypes {
	AUTH_INIT_REQUEST 				= '@@auth/AUTH_INIT_REQUEST',
	AUTH_INIT_SUCCESS 				= '@@auth/AUTH_INIT_SUCCESS',
	AUTH_INIT_FAILURE 				= '@@auth/AUTH_INIT_FAILURE',
	AUTH_LOGIN_REQUEST 				= '@@auth/AUTH_LOGIN_REQUEST',
	AUTH_LOGIN_SUCCESS 				= '@@auth/AUTH_LOGIN_SUCCESS',
	AUTH_LOGIN_FAILURE 				= '@@auth/AUTH_LOGIN_FAILURE',
	AUTH_LOGOUT_REQUEST 			= '@@auth/AUTH_LOGOUT_REQUEST',
	AUTH_LOGOUT_SUCCESS 			= '@@auth/AUTH_LOGOUT_SUCCESS',
	AUTH_LOGOUT_FAILURE 			= '@@auth/AUTH_LOGOUT_FAILURE',
}

// Action Params for LogIn
export interface ILoginParams {
	email:		string,
	password:	string,
}

export interface ILoginResponse {
	accessToken:		string,
	refreshToken:	  string,
}
