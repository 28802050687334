

export interface IQueryParam {
  field: string,
  opStr: EOperators,
  value: any | null,
}

export interface IExtraQueryParam {
  kind: EExtraQueryType,
  value: string | string[] | boolean,
}

export interface ISortParam {
  field: string,
  dir: EDirection,
}

export interface IPaginationParam {
  page: number,
  limit: number,
}

export interface IParamsTypes {
  countryId?: number,
  stateRegionId?: number,
  provinceId?: number,
  userId?: number,

  sortParams?: ISortParam[],
  paginationParams?: IPaginationParam,
  queryParams?: IQueryParam[],
  extraQuery?:  IExtraQueryParam[],
}

export enum EOperators {
  EQU = '$eq',
  NEQ = '$ne',
  LST = '$lt',
  LTE = '$lt',
  GRT = '$gt',
  GTE = '$gte',
}

export enum EDirection {
  ASC    = 'asc',
  DESC   = 'desc',
}

export enum EActionType {
  CREATE  = 'CREATE',
  UPDATE  = 'UPDATE',
  DELETE  = 'DELETE',
}

export enum EExtraQueryType {
  JOIN  = 'join',
  WITH_SORT     = '$withSort',
  WITH_DELETED  = '$withDeleted',
  WITH_FILTER   = '$withFilter',
  COUNT         = '$count'
}
