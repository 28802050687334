import {ERolesActionTypes, IRolesAction} from "./index";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../../index";
import {EDirection, IPaginationParam, IParamsTypes, ISortParam} from "../../../helper/queryParam/types";
import {config} from "../../../appConfig"
import axios from 'axios';
import RoleModel from "../../../model/roleModel";
import {IRoleDoc} from "../role";
import {IServerResMany} from "../../types";
import {push} from "connected-react-router";
import {actAuth} from "../auth";
import {checkToken} from "../../../helper/token/auth_helper";
import {IGetTokenReturn} from "../../../helper/token/types";

// Default Query
const defaultOrderParams: ISortParam[] = [
	{
		field: 'id',
		dir: EDirection.ASC,
	},
];

const defaultPaginationParams: IPaginationParam = {
	page: 1,
	limit: 25,
};

export const actRoles = {
	get: ({
					queryParams = [],
					sortParams = defaultOrderParams,
					paginationParams = defaultPaginationParams
				}: IParamsTypes)  => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => { // ,	getState: Function

			dispatch(actionRequestOn(ERolesActionTypes.GET_REQUEST));
			const params: IParamsTypes = {
				queryParams,
				sortParams,
				paginationParams,
			};

			const token: IGetTokenReturn = await checkToken();
			if (token.accToken) {
				try {
					const url = `${config.site.apiURL}/api/auth/roles?`;
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;

					const queryAxios = '';
					const response = await axios.get<IServerResMany<IRoleDoc>>(url + queryAxios);

					const docs: IRoleDoc[] = response.data.data.map((n: IRoleDoc) => {
						return new RoleModel(n);
					});

					return dispatch(actionRequestOff(
						ERolesActionTypes.GET_SUCCESS,
						docs,
						params,
						response.data.count,
						response.data.total,
						response.data.page,
						response.data.pageCount,
						)
					);
				} catch (error) {
					console.log('AXIOS error: ', JSON.stringify(error));
					console.log('AXIOS error: ', error);
					if (error.response && error.response.status === 401) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
					} else if (error.response && error.response.status === 403) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
					} else if (error.response && error.response.status === 400) {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					} else {
						//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
					}
					// Close error
					return dispatch(actionRequestOff(ERolesActionTypes.GET_FAILURE, [], params));
				}
			}
			else {
				dispatch(push('/'));
				dispatch(actionRequestOff(ERolesActionTypes.GET_FAILURE, [], params));
				dispatch(actAuth.logout());
			}
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ERolesActionTypes) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IRolesAction = {
			type: type,
			payload: {
				data: [],
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (
	type: ERolesActionTypes,
	data: IRoleDoc[],
	params: IParamsTypes,
	count?: number,
	total?: number,
	page?: number,
	pageCount?: number,
	) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IRolesAction = {
			type: type,
			payload: {
				params: !!params ? params : {},
				isFetching: false,
				data: !!data ? data : [],
				count: !!count ? count : null,
				total: !!total ? total : null,
				page: !!page ? page : null,
				pageCount: !!pageCount ? pageCount : null,
			},
		};
		dispatch(action);
	}
};
