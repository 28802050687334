import {IRoleDoc} from "../role";
import {IParamsTypes} from "../../../helper/queryParam/types";


export interface IRolesState {
	readonly params: 			IParamsTypes,
	readonly isFetching: 	boolean,
	readonly data: 				IRoleDoc[],
	readonly count:				number,
	readonly total:				number,
	readonly page:				number,
	readonly pageCount:		number,
}

export interface IRolesAction {
	type: ERolesActionTypes,
	payload: {
		params?: 			IParamsTypes,
		isFetching?: 	boolean,
		data?: 				IRoleDoc[],
		count?:       number | null,
		total?:       number | null,
		page?:        number | null,
		pageCount?:   number | null,
	},
}

export enum ERolesActionTypes {
	GET_REQUEST = '@@roles/GET_REQUEST',
	GET_SUCCESS = '@@roles/GET_SUCCESS',
	GET_FAILURE = '@@roles/GET_FAILURE',
}
