import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {ICheckToken} from "../token/types";
import {EDirection, IExtraQueryParam, IPaginationParam, IParamsTypes, IQueryParam, ISortParam} from "./types";

const defaultOrderParams: ISortParam[] = [
	{
		field: 'id',
		dir: EDirection.ASC,
	},
];

const defaultPaginationParams: IPaginationParam = {
	page: 1,
	limit: 10,
};

const defaultQueryParam: IQueryParam[] = [];

const defaultExtraParam: IExtraQueryParam[] = [];



export const queryParamDecode = (params: IParamsTypes): string => {
	const {queryParams, extraQuery, sortParams, paginationParams} = params;
	const qb: RequestQueryBuilder = RequestQueryBuilder.create();
	if (!!queryParams) {
		queryParams.forEach((qp: IQueryParam, index: number) => {
			if (index == 0) {
			}
		})
	}

	if (!!sortParams) {
		sortParams.forEach((sp: ISortParam, index: number) => {
				qb.sortBy({field: sp.field, order: sp.dir === EDirection.ASC ? 'ASC' : 'DESC'})
		})
	}



	qb.setPage(paginationParams?.page ?? defaultPaginationParams.page);
	qb.setLimit(paginationParams?.limit ?? defaultPaginationParams.limit);



	return qb.query();
}
