import {Action} from 'redux';
import { batch } from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {IApplicationState} from "../../index";
import {EAuthActionTypes, IAuthAction, ILoginParams, ILoginResponse} from './index';
import {checkToken, deleteToken, initToken, setToken} from "../../../helper/token/auth_helper";
import {ICheckToken} from "../../../helper/token/types";
import axios, {AxiosResponse} from "axios";
import {config} from "../../../appConfig";
import { push } from 'connected-react-router';

import * as routes from '../../../routes/RoutesList';
import {IUserDoc} from "../user";
import {IRoleDoc} from "../role";
import UserModel from "../../../model/userModel";
import RoleModel from "../../../model/roleModel";

export const actAuth = {
	// Init
	init: () => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => { // ,	getState: Function
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_INIT_REQUEST));

			const initTokens: ICheckToken = await initToken();
			const userId: string | null = initTokens.userId;
			const accToken: string | null = initTokens.accToken;
			// console.log('Token: ', userId);
			// console.log('API URL: ', config.site.apiURL);
			// IF Token!
			if (!!userId && !!accToken) {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + accToken;
				const url = `${config.site.apiURL}/api/auth/users/${userId}?join=role`;
				const response: AxiosResponse<IUserDoc> = await axios.get<IUserDoc>(url);
				const user: IUserDoc = response.data;
				if (!!user && user.isActive) {
					const role: IRoleDoc | null = user.role
					dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_SUCCESS, user, role));
				} else {
					dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_SUCCESS));
				}
			}
			else {
				dispatch(actionRequestOff(EAuthActionTypes.AUTH_INIT_SUCCESS));
			}
		}
	},

	// Login
	login: (params: ILoginParams) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => { // ,	getState: Function
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_LOGIN_REQUEST));

			try {

				const response = await axios.post<ILoginResponse>(`${config.site.apiURL}/api/auth/signin`, params);
				const accToken: string | null = response.data.accessToken ? response.data.accessToken : null;

				if (!!accToken) {
					setToken(response.data);
					const initTokens: ICheckToken = await checkToken();
					const userId: string | null = initTokens.userId;
					const accToken: string | null = initTokens.accToken;
					// IF Token!
					if (!!userId&& !!accToken) {
						axios.defaults.headers.common['Authorization'] = 'Bearer ' + accToken;
						const url = `${config.site.apiURL}/api/auth/users/${userId}?join=role`;
						const response: AxiosResponse<IUserDoc> = await axios.get<IUserDoc>(url);
						const user: IUserDoc = new UserModel(response.data);
						if (!!user && user.isActive) {
							const role: IRoleDoc | null = user.role
							dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGIN_SUCCESS, user, role));
						} else {
							dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGIN_SUCCESS));
						}
					}
					else {
						dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGIN_SUCCESS));
					}
				}
			} catch (error) {
				console.log('error: ', error.response);
				if (error.response && error.response.status === 401) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
				}
				else if (error.response && error.response.status === 403) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
				}
				else if (error.response && error.response.status === 400) {
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				else{
					//dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
				}
				// Close error
				return dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGIN_FAILURE));
			}

		}
	},

	logout: () => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
			dispatch(actionRequestOn(EAuthActionTypes.AUTH_LOGOUT_REQUEST));

			deleteToken();

			dispatch(actionRequestOff(EAuthActionTypes.AUTH_LOGOUT_SUCCESS, null));
			dispatch(push(routes.LANDING));
		}
	},

};



// Service for Fetching
const actionRequestOn = (type: EAuthActionTypes) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IAuthAction = {
			type: type,
			payload: {
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: EAuthActionTypes, user?: IUserDoc | null, role?: IRoleDoc | null) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IAuthAction = {
			type: type,
			payload: {
				isAuthenticated: !!user,
				isFetching: false,
				isInitializing: false,
				user: !!user ? new UserModel(user) : null,
				role: !!role ? new RoleModel(role) : null,
			},
		};
		dispatch(action);
	}
};

