import React from "react";


import { IAuthState} from "../store/auth/auth";
import {IApplicationState} from "../store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {connect} from "react-redux";
import {actCustomers, ICustomersState} from "../store/enty/customers";
import {CustomersCard} from "./component/customersCard";
import {IParamsTypes} from "../helper/queryParam/types";

interface PropsFromState {
	authState: IAuthState
	customersState: ICustomersState,
}

interface PropsFromDispatch {
	actCustomersGet: (param: IParamsTypes) => void,
}


export type IProps = PropsFromState & PropsFromDispatch;


class Dashboard extends React.Component<IProps> {

	componentDidMount = () => {
		this.props.actCustomersGet({});
	}


	render() {
		return(
			<div style={{display: "flex", width: '100%', flexFlow: 'row wrap', justifyContent: 'center'}}
				>
				<CustomersCard {...this.props}/>
			</div>
		)
	}

}

const mapStateToProps = (state: IApplicationState): PropsFromState => ({
	authState:  state.authState,
	customersState: state.customersState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
	//AUTH Actions
	// actAuthLogout: () => dispatch(actAuth.logout()),
	//LAYOUT Actions
	actCustomersGet: (param: IParamsTypes) => dispatch(actCustomers.get(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
