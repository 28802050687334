
import { ReactElement} from "react";
import {SvgIcon} from "@material-ui/core";

import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PublicIcon from '@material-ui/icons/Public';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import FaceIcon from '@material-ui/icons/Face';

export const DASHBOARD = '/dashboard';
export const HOME = '/dashboard';
export const LANDING = '/';
export const SIGN_IN = '/sign_in';
export const FORM_REGISTRATION = '/form_registration';
export const FORM_REGISTRATION_NEW = '/form_registration_new';

export const USERS = '/users';
export const CUSTOMERS = '/customers';
export const ROLES = '/roles';
export const SESSIONS = '/sessions';

export enum Routes {
	DASHBOARD = '/dashboard',
	HOME = '/dashboard',
	LANDING = '/',
	SIGN_IN = 'sign_in',
	FORM_REGISTRATION = '/form_registration',
	USERS = '/users',
	ROLES = '/roles',
	SESSIONS = '/sessions',
	CUSTOMERS = '/customers',
}

export interface IItemMenu {
	key: string,
	icon: ReactElement,
	route: Routes | null,
	father: string | null,
	submenu: IItemMenu[],
}

// Primary menu voices
export const primaryMenu: IItemMenu[] = [];

const item00: IItemMenu = {
	key: 'Dashboard',
	icon: <DashboardIcon/>,
	route: Routes.DASHBOARD,
	father: null,
	submenu: [],
}
primaryMenu.push(item00)

// Element 1
const item11: IItemMenu = {
	key: 'Utenti',
	icon: <PersonIcon />,
	route: Routes.USERS,
	father: 'Amministrazione',
	submenu: [],
}
const item12: IItemMenu = {
	key: 'Ruoli',
	icon: <FitnessCenterIcon />,
	route: Routes.ROLES,
	father: 'Amministrazione',
	submenu: [],
}
const item01: IItemMenu = {
	key: 'Amministrazione',
	icon: <SettingsIcon/>,
	route: null,
	father: null,
	submenu: [item11, item12],
}
primaryMenu.push(item01)

const item02: IItemMenu = {
	key: 'Geografia',
	icon: <PublicIcon />,
	route: null,
	father: null,
	submenu: [],
}
primaryMenu.push(item02)

// Element 3
const item31: IItemMenu = {
	key: 'Clienti',
	icon: <FaceIcon />,
	route: Routes.CUSTOMERS,
	father: 'Contatti',
	submenu: [],
}
const item03: IItemMenu = {
	key: 'Contatti',
	icon: <PeopleIcon />,
	route: null,
	father: null,
	submenu: [item31],
}
primaryMenu.push(item03)

// Secondary menu voices
export const secondaryMenu: IItemMenu[] = [];

secondaryMenu.push(item11);
secondaryMenu.push(item12)


secondaryMenu.push(item31)
