export interface ICustomerDoc {
	id?: 					    string | null,
	email:					  string,
	username:					string,
	ticket:					  string,
	name:					    string,
	surname:					string,
	company:					string,
	gender:					  string,
	telephone:				string,

	createdAt?:				Date,
	updatedAt?:				Date,
	deletedAt?:				Date | null,
	_delete?:				  boolean,

	isAccepted:       boolean,
	isSecondAccepted: boolean,

	reCaptchaToken?:		string,
}

export interface ICustomerState {
	readonly data: 				ICustomerDoc,
	readonly isFetching: 	boolean,
}

export interface IModalState {
	readonly isOpen: 	boolean,
	readonly isError: 	boolean,
}

export interface ICustomerAction {
	type: ECustomerActionTypes,
	payload: {
		data?:        ICustomerDoc,
		isFetching?:  boolean
	},
}

export interface IModalAction {
	type: ECustomerActionTypes,
	payload: {
		isOpen:   boolean,
		isError: 	boolean,
	},
}

export enum ECustomerActionTypes {
	INIT_MODEL = '@@customer/INIT_MODEL',
	GET_REQUEST = '@@customer/GET_REQUEST',
	GET_SUCCESS = '@@customer/GET_SUCCESS',
	GET_FAILURE = '@@customer/GET_FAILURE',
	POST_REQUEST = '@@customer/POST_REQUEST',
	POST_SUCCESS = '@@customer/POST_SUCCESS',
	POST_FAILURE = '@@customer/POST_FAILURE',
	PUT_REQUEST = '@@customer/PUT_REQUEST',
	PUT_SUCCESS = '@@customer/PUT_SUCCESS',
	PUT_FAILURE = '@@customer/PUT_FAILURE',
	DELETE_REQUEST = '@@customer/DELETE_REQUEST',
	DELETE_SUCCESS = '@@customer/DELETE_SUCCESS',
	DELETE_FAILURE = '@@customer/DELETE_FAILURE',
	OPEN_SUCCESS_MODAL = '@@customer/OPEN_SUCCESS_MODAL',
	OPEN_ERROR_MODAL = '@@customer/OPEN_ERROR_MODAL',
}
