import { ERoleActionTypes, IRoleAction, IRoleDoc } from "./index";
import { Action } from "redux";
import { batch } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import {checkToken, initToken} from "../../../helper/token/auth_helper";
import {actAuth} from "../auth";
import axios from "axios";
import {config} from "../../../appConfig";
import {IGetTokenReturn} from "../../../helper/token/types";
import {actRoles} from "../roles";
import {push} from "connected-react-router";
import RoleModel from "../../../model/roleModel";

export const actRole = {
	create: (doc: IRoleDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ERoleActionTypes.POST_REQUEST, doc));

			const rolesState = getState().rolesState;
			const params = !!rolesState && !!rolesState.params ? rolesState.params : {};
			const token: IGetTokenReturn = await checkToken();

			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.post<IRoleDoc>(`${config.site.apiURL}/api/auth/roles`, RoleModel.toDB(doc));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ERoleActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ERoleActionTypes.POST_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actRoles.get(params));
				dispatch(actionRequestOff(ERoleActionTypes.POST_SUCCESS, doc));
			});
		}
	},

	update: (doc: IRoleDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ERoleActionTypes.PUT_REQUEST, doc));

			const rolesState = getState().rolesState;
			const params = !!rolesState && !!rolesState.params ? rolesState.params : {};
			const token: IGetTokenReturn = await checkToken();
			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.put<IRoleDoc>(`${config.site.apiURL}/api/auth/roles/${doc.id}`,  RoleModel.toDB(doc));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ERoleActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ERoleActionTypes.PUT_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actRoles.get(params));
				dispatch(actionRequestOff(ERoleActionTypes.PUT_SUCCESS, doc));
			});
		}
	},

	get: (doc?: IRoleDoc, id?: number) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action> ) => {   //, getState: Function
			dispatch(actionRequestOn(ERoleActionTypes.GET_REQUEST));

			const getDoc: IRoleDoc = !!doc ? doc : new RoleModel();

			if (!!doc) {
				return dispatch(actionRequestOff(ERoleActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const response = await axios.get<IRoleDoc>(`${config.site.apiURL}/api/auth/roles/${id}` );

					return dispatch(actionRequestOff(ERoleActionTypes.GET_SUCCESS, response.data));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ERoleActionTypes.GET_FAILURE));
				}
			}

			return dispatch(actionRequestOff(ERoleActionTypes.GET_FAILURE));
		}
	},

	delete: (doc: IRoleDoc) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ERoleActionTypes.DELETE_REQUEST, doc));

			const customersState = getState().customersState;
			const params = !!customersState && !!customersState.params ? customersState.params : {};
			const token: IGetTokenReturn = await checkToken();

			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.delete(`${config.site.apiURL}/api/auth/roles/${doc.id}`,{ data: { "$hardDelete": false }});
				}
				catch (error) {
					console.log('error: ', error);
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ERoleActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ERoleActionTypes.DELETE_FAILURE));
					dispatch(actAuth.logout());
				});

			}
			batch(() => {
				dispatch(actRoles.get(params));
				dispatch(actionRequestOff(ERoleActionTypes.DELETE_SUCCESS, doc));
			});
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ERoleActionTypes, doc?: IRoleDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IRoleAction = {
			type: type,
			payload: {
				doc: !!doc ? new RoleModel(doc) : new RoleModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: ERoleActionTypes, doc?: IRoleDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IRoleAction = {
			type: type,
			payload: {
				doc: !!doc ? new RoleModel(doc) : new RoleModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
