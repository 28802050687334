interface ILocaleOptions {
	locale: string,
	name: string,
}

export interface IConfig {
	defaultLocale: string,

	locales: {
		[key: string]: string
	}

	languageOptions: ILocaleOptions[],

	modules: {
		base: {
			editProfile: boolean,
			pwdChange: boolean,
			pwdForgot: boolean,
			signIn: boolean,
			signInGoogle: boolean,
			signInFacebook: boolean,
			signUp: boolean,
			signOut: boolean,
		}
	}
	notification: {
		duration: number,
	},

	request: {
		pageLimit: number,
	},

	site: {
		apiURL: string,
		localStorage: string,
	}

}

export const config: IConfig = {
  defaultLocale: 'it',

  languageOptions: [
		{ locale: 'it-IT',
			name: 'Italiano',
		},
		{ locale: 'en-US',
			name: 'English'
		},
  ],

	locales: {
		'en': 'english',
		'it': 'italiano',
	},

	modules: {
		base: {
			editProfile: true,
			pwdChange: true,
			pwdForgot: true,
			signIn: true,
			signInGoogle: true,
			signInFacebook: true,
			signUp: true,
			signOut: true,
		},
	},

	notification: {
		duration: 4000,
	},

	request: {
  	pageLimit: 25,
	},

	site: {
		// apiURL: 'http://localhost:4000',
		apiURL: process.env.NODE_ENV === 'production' ? 'https://registrazione.ecquologia.com' : 'http://localhost:4000',
		localStorage: !!process.env.REACT_APP_LS_KEY ? process.env.REACT_APP_LS_KEY : '€RTFTVAPippoPippo9736524$£@@',
	}
};


//    Server
// 		apiURL: 'http://localhost:4000/api',
//    apiURL: 'http://45.62.237.5/api',
