import {
  Column,
  HeaderGroup,
  TableInstance,
  TableState,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable
} from 'react-table'
import React, {FunctionComponent} from 'react'
import {IProps} from "../customers";
import {ICustomerDoc} from "../../store/enty/customer";
import XLSX from 'xlsx';
import {
  Button,
  createStyles,
  IconButton,
  InputBase,
  makeStyles,
  MenuItem, Paper,
  Select, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  withStyles
} from "@material-ui/core";
import MaUTable from '@material-ui/core/Table'
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import {useWindowWidth} from "@react-hook/window-size";
import {EDirection, ISortParam} from "../../helper/queryParam/types";
import {isDate} from "date-fns";
import CustomerModel from "../../model/customerModel";

const useStyles = makeStyles(() => {
  return createStyles({
    textFieldRoot: {
      padding: 0,
    },
    input: {
      padding: 0,
      borderRadius: 4,
      width: '80px',
    },
    th: {
      background: '#d9d7db',
      color: 'black',
      fontWeight: 'bold',
      padding: '4px',
    },
    td: {
      padding: '10px',
      // border: 'solid 1px',
      // borderColor: '#28262a',
      // background: '#969497',
    },
    tr0: {
      border: 'solid 1px',
      borderColor: '#28262a',
      // background: '#969497',
    },
    tr1: {
      border: 'solid 1px',
      borderColor: '#28262a',
      background: '#969497',
    },
    table: {
      border: 'solid 1px',
      borderColor: '#28262a',
    },
  })

});

interface ITable {
  columns: Column<ICustomerDoc>[],
  data: ICustomerDoc[],
}

interface IState {
  state: UsePaginationState<ICustomerDoc>
}

type TableInstancePagination =
  TableInstance<ICustomerDoc>
  & UsePaginationInstanceProps<ICustomerDoc>
  & TableState<ICustomerDoc>
  & IState

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fefefe',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function Table(props: ITable & IProps) {
  const {columns, data} = props;
  const classes = useStyles();

  const tableResult = useTable(
    {
      columns,
      data,
    },
    usePagination
  ) as TableInstancePagination

  const handleNextPage = () => {
    if (!!props.customersState.params && !!props.customersState.params.paginationParams && !!props.customersState.params.paginationParams.page && props.customersState.page !== props.customersState.pageCount) {
      const params = {
        ...props.customersState.params,
        paginationParams: {...props.customersState.params.paginationParams, page: props.customersState.page + 1}
      };

      props.actCustomersGet(params);
    }
  }

  const handlePreviousPage = () => {
    if (!!props.customersState.params && !!props.customersState.params.paginationParams && !!props.customersState.params.paginationParams.page && props.customersState.page - 1 > 0) {
      const params = {
        ...props.customersState.params,
        paginationParams: {...props.customersState.params.paginationParams, page: props.customersState.page - 1}
      };

      props.actCustomersGet(params);
    }
  }

  const handleGoToPage = (pageNumber: number) => {
    if (!!props.customersState.params && !!props.customersState.params.paginationParams && pageNumber !== 0 && pageNumber <= props.customersState.pageCount) {
      const params = {
        ...props.customersState.params,
        paginationParams: {...props.customersState.params.paginationParams, page: pageNumber}
      };

      props.actCustomersGet(params);
    }
  }

  const handleChangeLimit = (limitNumber: number) => {
    if (!!props.customersState.params && !!props.customersState.params.paginationParams) {
      const params = {
        ...props.customersState.params,
        paginationParams: {...props.customersState.params.paginationParams, limit: limitNumber}
      };

      props.actCustomersGet(params);
    }
  }

  const handleChangeSort = (property: string, dir: EDirection) => {
    if (!!props.customersState.params && !!props.customersState.params.sortParams) {
      const sortParamsProps = props.customersState.params.sortParams

      let newSortParams:ISortParam[] = []
      if (sortParamsProps.some(x => x.field === property && !dir)){
        newSortParams = sortParamsProps.filter(x => x.field !== property);
      }else{
        newSortParams = [...sortParamsProps.filter(x => x.field !== property)]
        newSortParams.push({field:property, dir: dir})
      }

      const params = {
        ...props.customersState.params,
        sortParams: newSortParams
      };

      props.actCustomersGet(params);
    }
  }

  const handleExportXlsx = async () => {

    const dataOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
    }

    const dataExportOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit'
    }

    const customerToExport: ICustomerDoc[] | undefined  = await props.actCustomersExport(props.customersState.params);

    if(!!customerToExport){
      const dataExp = customerToExport.map(customer=>{
        const obj = {};
        Object.keys(CustomerModel.toDB(customer)).forEach((oKey) => {
          if (columns.some(x => x.accessor === oKey)){
            // @ts-ignore
            const value = customer[oKey];
            const columnInfo = columns.filter(x => x.accessor === oKey)[0];
            const columnName = columnInfo.Header as string;
            if (!isDate(value)){
              Object.assign(obj, {[columnName]: value})
            } else {
              Object.assign(obj, {[columnName]: value.toLocaleDateString('it-IT', dataOptions)})
            }
          }
        });
        return obj;
      });

      const book = XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(dataExp);
      XLSX.utils.book_append_sheet(book, sheet, "Clienti");
      XLSX.writeFile(book, "ExportClienti_"+ (new Date).toLocaleDateString('it-IT', dataExportOptions) +".xlsx");
    }

  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = tableResult

  // Render the UI for your table
  return (

      <TableContainer component={Paper} style={{backgroundColor:'transparent'}}>
        <MaUTable {...getTableProps()} className={classes.table}>
          <EnhancedTableHead
            headerGroups={headerGroups}
            sortParams={props.customersState.params.sortParams}
            onRequestSort={(property: any, dir:any)=>handleChangeSort(property, dir)}
          />
          <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const even = i % 2;
            let classname = classes.tr0;
            if (!even) {
              classname = classes.tr0
            } else {
              classname = classes.tr1
            }
            return (
              <TableRow {...row.getRowProps()} className={classname}>
                {row.cells.map(cell => {
                  return <TableCell className={classes.td}
                             {...cell.getCellProps()}
                  >{cell.render('Cell')}</TableCell>
                })}
              </TableRow>
            )
          })}
          </TableBody>
        </MaUTable>
        <div className="pagination" style={{textAlign: 'right', paddingTop: '20px'}}>
          <IconButton
            onClick={() => handleGoToPage(1)}
            disabled={props.customersState.page === 1}
            aria-label="first page"
          >
            <FirstPageIcon/>
          </IconButton>
          <IconButton
            onClick={handlePreviousPage}
            disabled={props.customersState.page - 1 === 0}
            aria-label="previous page"
          >
            {<KeyboardArrowLeft/>}
          </IconButton>
          <span>
                Pagina{' '}
            <strong>
                  {props.customersState.page} di {props.customersState.pageCount}
                </strong>{' '}
              </span>
          <span>
            <IconButton
              onClick={handleNextPage}
              disabled={props.customersState.page === props.customersState.pageCount}
              aria-label="next page"
            >
              {<KeyboardArrowRight/>}
            </IconButton>
            <IconButton
              onClick={() => handleGoToPage(props.customersState.pageCount)}
              disabled={props.customersState.page === props.customersState.pageCount}
              aria-label="last page"
            >
              <LastPageIcon/>
            </IconButton>
              Vai alla pagina:{' '}
            <TextField
              classes={{ root: 'margin: 5px;'}}
              inputProps={{
                style: {
                  borderRadius: 3,
                  padding: 8,
                  paddingLeft: 8,
                  marginRight: 5,
                  marginLeft: 5,
                  // marginTop: -5,
                  marginBottom: 2,
                  width: 35,
                }
              }}
              id="standard-basic"
              variant="outlined"
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) : 0
                handleGoToPage(page)
              }}
              size={'small'}
            />
          </span>{' '}
          Risultati per pagina:{' '}
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={props.customersState.params.paginationParams?.limit}
            onChange={e => handleChangeLimit(Number(e.target.value))}
            input={<BootstrapInput/>}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Button color="primary" onClick={handleExportXlsx} title='Export'>
            Esporta
          </Button>
        </div>

    </TableContainer>
  )
}

function EnhancedTableHead(props: { headerGroups: any; sortParams: ISortParam[] | undefined, onRequestSort: any; }) {
  const {headerGroups, sortParams, onRequestSort} = props;

  const createSortHandler = (property: any, dir: any) => (event: any) => {
    let dirChanged = null
    if(!!dir){
      if( dir === EDirection.ASC ){
        dirChanged=EDirection.DESC
      }
    }else{
      dirChanged=EDirection.ASC
    }
    onRequestSort(property, dirChanged);
  };

  return (
    <TableHead>
      {headerGroups.map((headerGroup:  HeaderGroup<ICustomerDoc>) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <TableCell
              {...column.getHeaderProps()}
              key={column.id}
              align={'left'}
              padding={'default'}
              sortDirection={false }
            >
              <TableSortLabel
                active={!!sortParams && sortParams.some(x => x.field === column.id)}
                hideSortIcon={true}
                direction={!!sortParams && sortParams.some(x => x.field === column.id) ? sortParams.filter(x => x.field === column.id)[0].dir : undefined}
                onClick={createSortHandler(column.id, !!sortParams && sortParams.some(x => x.field === column.id) ? sortParams.filter(x => x.field === column.id)[0].dir : undefined)}
              >
                {column.render('Header')}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
}

const CustomersList: FunctionComponent<IProps> = (props: IProps) => {
  const onlyWidth = useWindowWidth();
  const dataOptions = {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
  }
  const columns: Column<ICustomerDoc>[] = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email', // accessor is the "key" in the data
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Cognome',
        accessor: 'surname',
      },
      {
        Header: 'Ticket',
        accessor: 'ticket',
      },
      {
        Header: 'Data iscrizione',
        accessor: 'createdAt',
        Cell: (data) => {
          return !!data.value ? data.value.toLocaleDateString('it-IT', dataOptions) : null
        }
      },
      {
        Header: 'Data Aggiornamento',
        accessor: 'updatedAt',
        Cell: (data) => {
          return !!data.value ? data.value.toLocaleDateString('it-IT', dataOptions) : null
        }
      },
    ],
    []
  )

  const data = props.customersState.data

  return (
    <Table
      {...props}
      columns={columns}
      data={data}
    />
  )
}

export default CustomersList

