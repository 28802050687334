import {IItemMenu} from "../../routes/RoutesList";

export interface ILayoutDoc {
	title:              string,
	drawerDim:					number,
	isMobile: 				  boolean,
	isWeb:     				  boolean,
	isDrwOpen:          boolean,
	isViewSmall:        boolean,
}

export interface ILayoutState {
	title:              string,
	readonly drawerDim:					  number,
	readonly isMobile: 				    boolean,
	readonly isWeb:     				  boolean,
	readonly isDrwOpen:           boolean,
	readonly isViewSmall:         boolean,
}

export interface ILayoutAction {
	type: ELayoutActionTypes,
	payload: {
		title?:               string,
		drawerDim?:			      number,
		isMobile?: 				    boolean,
		isWeb?:     				  boolean,
		isDrwOpen?:           boolean,
		isViewSmall?:         boolean,
	}
}

export enum ELayoutActionTypes {
	INIT_STATUS = '@@Layout/INIT_STATUS',
	TOGGLE_OPEN = '@@Layout/TOGGLE_OPEN',
	TOGGLE_RESIZE = '@@Layout/TOGGLE_RESIZE',
	TOGGLE_TITLE = '@@Layout/TOGGLE_TITLE',
}
