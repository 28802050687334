import * as Yup from 'yup';
import { ICustomerDoc } from "../store/enty/customer";
import {ValidationError} from "yup";

const syncValidationSchema = Yup.object({
	email:		    Yup.string()
		.email('Email non formattata correttamente')
		.max(255, 'Non può essere così lunga')
		.required('Non può essere lasciata in bianco'),
	name:         Yup.string()
		.max(128, 'Non può essere così lungo')
		.nullable(true),
	surname:      Yup.string()
		.max(128, 'Non può essere così lungo')
		.nullable(true),
	company:      Yup.string().max(128).nullable(true),
	isAccepted:   Yup.boolean().required().oneOf([true], 'Deve essere accettata'),
});

const submitValidationSchema = Yup.object({
	email:		    Yup.string()
		.email('Email non formattata correttamente')
		.max(255, 'Non può essere così lunga')
		.required('Non può essere lasciata in bianco'),
	name:         Yup.string()
		.max(128, 'Non può essere così lungo')
		.required('Non può essere lasciato in bianco'),
	surname:      Yup.string()
		.max(128, 'Non può essere così lungo')
		.required('Non può essere lasciato in bianco'),
	company:      Yup.string().max(128).nullable(true),
	isAccepted:   Yup.boolean()
		.required('Scelta obbligatoria')
		.oneOf([true]),
});

const options = {
	strict: false,
	abortEarly: false,
	stripUnknown: false,
	recursive: true
}

export const validate = (values: ICustomerDoc, isSubmitting: boolean) => {
	const errors: Record<string, string> = {};

	try {
		if (isSubmitting) {
			submitValidationSchema.validateSync(values, options);
		} else {
			syncValidationSchema.validateSync(values, options);
		}
	} catch(err) {
		Object.keys(values).map(key => {
			err.inner.forEach((error: ValidationError) => {
				if (error.path === key && !errors[key]) {
					errors[key] = error.message;
				}
			});
		});
	}

	return errors;
}
