import React, {ReactNode} from "react";


import {actAuth, IAuthState} from "../store/auth/auth";
import {IApplicationState} from "../store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {connect} from "react-redux";
import {actLayout, ILayoutState} from "../store/layout";
import {LOAppBar} from "./layout/appBar";
import {LOBody} from "./layout/body";
import {LODrawer} from "./layout/drawer";
import {RouterState} from "connected-react-router";

interface PropsFromState {
	authState: IAuthState
	layoutState: ILayoutState,
	router: RouterState,
}

interface InheritedProperties {
	children?: ReactNode | ReactNode[],
}

interface IState {
	number?: string,
}

interface PropsFromDispatch {
	actAuthLogout: () => void,

	actLayoutToggleOpen: () => void,
	actLayoutToggleSize: (width: number) => void
	actLayoutToggleTitle: (title: string) => void;
}

export type IProps = PropsFromState & PropsFromDispatch & InheritedProperties & IState;


class Layout extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			number: '000',
		}

	}

	componentDidMount = () => {
		const number = Math.floor(Math.random() * 40).toString().padStart(3, '0');
		this.setState({
			number: number,
		})
		setInterval(() => {
			const number = Math.floor(Math.random() * 40).toString().padStart(3, '0');
			this.setState({
				number: number,
			})
		}, 300000)
	}

	getBackground = () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const photo = require(`../assets/images/background/${this.state.number}.jpg`);
		return photo.default;
	}

	render() {
		console.log('Layout: ', this.props.layoutState);
		return(
			<div style={{display: "flex", width: '100vw',
				height: '100vh', backgroundImage: `url(${this.getBackground()})`,
				backgroundSize: 'cover'}}>


				<div style={{display: 'flex', flexDirection: 'column'}}>

					<LOAppBar {...this.props} />
					<div style={{width: '100%',
						height: '100%',
						overflow: 'hidden'}}>
						<LOBody {...this.props} />
					</div>

				</div>

				<LODrawer {...this.props} />
			</div>
		)
	}

}

const mapStateToProps = (state: IApplicationState): PropsFromState => ({
	authState:  state.authState,
	layoutState: state.layoutState,
	router: state.router,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
	//AUTH Actions
	actAuthLogout: () => dispatch(actAuth.logout()),
	//LAYOUT Actions
	actLayoutToggleOpen: () => dispatch(actLayout.toggleOpen()),
	actLayoutToggleSize: (width: number) => dispatch(actLayout.toggleSize(width)),
	actLayoutToggleTitle: (title: string) => dispatch(actLayout.toggleTitle(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
