import {IRoleDoc} from "../role";

export interface IUserDoc {
	id?: 					    string | null,
	email:					  string,
	isActive:         boolean,
	password?:				string,
	maxSessions:      number,

	createdAt?:				Date,
	updatedAt?:				Date,
	deletedAt?:				Date | null,

	// In relations
	_delete?:				  boolean,

	// Relations
	role:             IRoleDoc | null,
	// 4 Attachments
	// attach: 			IAttach,
}

export interface IUserState {
	readonly data: 				IUserDoc,
	readonly isFetching: 	boolean,

}

export interface IUserAction {
	type: EUserActionTypes,
	payload: {
		data?:        IUserDoc,
		isFetching?:  boolean
	},
}

export enum EUserActionTypes {
	GET_REQUEST = '@@user/GET_REQUEST',
	GET_SUCCESS = '@@user/GET_SUCCESS',
	GET_FAILURE = '@@user/GET_FAILURE',
	POST_REQUEST = '@@user/POST_REQUEST',
	POST_SUCCESS = '@@user/POST_SUCCESS',
	POST_FAILURE = '@@user/POST_FAILURE',
	PUT_REQUEST = '@@user/PUT_REQUEST',
	PUT_SUCCESS = '@@user/PUT_SUCCESS',
	PUT_FAILURE = '@@user/PUT_FAILURE',
	DELETE_REQUEST = '@@user/DELETE_REQUEST',
	DELETE_SUCCESS = '@@user/DELETE_SUCCESS',
	DELETE_FAILURE = '@@user/DELETE_FAILURE',
}
