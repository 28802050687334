export const nextAvatarName: () => string = () => {
	return 'yoo-no-lab-next-avatar';
};

export const originalAvatarName: () => string = () => {
	return 'yoo-no-lab-original-avatar';
};

export const accTokenName: () => string = () => {
	return 'yn-lab-ecquo-accToken';
};

export const refTokenName: () => string = () => {
	return 'yn-lab-ecquo-refToken';
};

