import { IRoleState, ERoleActionTypes } from './index';
import { Reducer } from 'redux';
import RoleModel from "../../../model/roleModel";


// Reducer initial State with ALL objects
export const initialState: IRoleState = {
	doc: new RoleModel(),
	isFetching: false,
};

const reducer: Reducer<IRoleState> = (state = initialState, action) => {
	switch (action.type) {
		case ERoleActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.GET_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ERoleActionTypes.POST_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.POST_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.POST_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ERoleActionTypes.PUT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.PUT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.PUT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ERoleActionTypes.DELETE_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.DELETE_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ERoleActionTypes.DELETE_FAILURE:
			return {
				...state,
				...action.payload,
			};

		//DEFAULT
		default:
			return state;
	}
};

export { reducer as RoleReducer }
