import {ILayoutState, ELayoutActionTypes} from './index';
import { Reducer } from 'redux';


// Reducer initial State with ALL objects
export const initialState: ILayoutState = {
	title:              '',
	drawerDim:					240,
	isMobile: 				  false,
	isWeb:     				  true,
	isDrwOpen:          true,
	isViewSmall:        false,
};


const reducer: Reducer<ILayoutState> = (state = initialState, action) => {
	switch (action.type) {
		case ELayoutActionTypes.INIT_STATUS:
			return {
				...state,
				...action.payload,
			};
		case ELayoutActionTypes.TOGGLE_OPEN:
			return {
				...state,
				...action.payload,
			};
		case ELayoutActionTypes.TOGGLE_RESIZE:
			return {
				...state,
				...action.payload,
			};
		case ELayoutActionTypes.TOGGLE_TITLE:
			return {
				...state,
				...action.payload,
			};

		// DEFAULT
		default:
			return state;
	}
};

export { reducer as LayoutReducer }

