import React, {Component} from "react";


import { IAuthState} from "../store/auth/auth";
import {IApplicationState} from "../store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {connect} from "react-redux";
import {actCustomers, ICustomersState} from "../store/enty/customers";
import {CustomersCard} from "./component/customersCard";
import {IParamsTypes} from "../helper/queryParam/types";
import CustomersList from "./component/customersList";
import {ICustomerDoc} from "../store/enty/customer";

interface PropsFromState {
	authState: IAuthState
	customersState: ICustomersState,
}

interface PropsFromDispatch {
	actCustomersGet: (param: IParamsTypes) => void,
	actCustomersExport: (param: IParamsTypes) => Promise<ICustomerDoc[] | undefined>,
}


export type IProps = PropsFromState & PropsFromDispatch;


class Dashboard extends Component<IProps> {

	componentDidMount = () => {
		this.props.actCustomersGet({});
	}


	render() {
		return(
			<div style={{width: '100%',
				height: '100%',
				overflow: 'auto', display: "flex", flexFlow: 'row wrap', justifyContent: 'center'}}>
				<CustomersList {...this.props}/>
			</div>
		)
	}

}

const mapStateToProps = (state: IApplicationState): PropsFromState => ({
	authState:  state.authState,
	customersState: state.customersState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.


const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
	//AUTH Actions
	// actAuthLogout: () => dispatch(actAuth.logout()),
	//LAYOUT Actions
	actCustomersGet: (param: IParamsTypes) => dispatch(actCustomers.get(param)),
	actCustomersExport: (param: IParamsTypes): Promise<ICustomerDoc[] | undefined> => dispatch(actCustomers.getExport(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
