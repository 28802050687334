import React from 'react';
import {EActionType} from '../helper/queryParam/types';
import {FormikProps, withFormik} from "formik";
import {ThunkDispatch} from "redux-thunk";
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormHelperText,
  TextField
} from '@material-ui/core';
import {
  actCustomer,
  ICustomerDoc,
  ICustomerState,
  IModalState
} from "../store/enty/customer";
import './registrationForm/registrationFormStyle.css';
import {validate} from "../model/customerValidation";
import {connect} from "react-redux";
import {IApplicationState} from "../store";
import {Action} from "redux";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

interface IProps {
  selectRow: ICustomerDoc,
  modalState: IModalState,
  actCustomerPost: (obj: ICustomerDoc) => void,
  actionType: EActionType,
  submitModal: (actionType: EActionType, row: ICustomerDoc) => void,
  closeModal: () => void,
  isFetching: boolean,
  customerState: ICustomerState,
}


type IForm = IProps & FormikProps<ICustomerDoc>

const form: React.FunctionComponent<IForm> = (props: IForm) => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const {handleSubmit, isSubmitting, isValid, isFetching, values, setFieldValue, handleBlur, errors, touched, customerState} = props;

  const submitDisabled: boolean = isSubmitting || isFetching || !isValid || !props.dirty;

  const handleSubmitForm = async () => {
    if (!!executeRecaptcha) {
      const token = await executeRecaptcha("form_post");
      if (!!token){
        setFieldValue('reCaptchaToken', token);
        handleSubmit()
      }
    }
  }

  return (
    <span id={'formExco'} style={{overflowY:'hidden'}}>

      {props.modalState.isOpen && !props.modalState.isError &&
        <Dialog fullScreen open={props.modalState.isOpen} style={{opacity:'0.85'}}>
          <div style={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', justifyContent:'center', margin:'5px'}}>
            <DoneIcon style={{ fontSize: 200, color: 'green' }} />
            <h3 style={{textAlign:'center'}}>Registrazione effettuata con successo</h3>
            <h4 style={{textAlign:'center'}}>Riceverai una mail con le istruzioni per accedere alla Fiera</h4>
          </div>
        </Dialog>
      }

      {props.modalState.isOpen && props.modalState.isError &&
        <Dialog fullScreen open={props.modalState.isOpen} style={{opacity:'0.85'}}>
          <div style={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', justifyContent:'center', margin:'5px'}}>
            <ErrorIcon style={{ fontSize: 200, color: 'red' }} />
            <h3 style={{textAlign:'center'}}>Errore durante la registrazione!!</h3>
            <h1 style={{textAlign:'center'}}>Per ulteriori info scrivi una mail <a href="mailto:contatti@ecquologia.com">qui</a></h1>
          </div>
        </Dialog>
      }

      <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '5px', backgroundColor:'#F6F1F182', height:'100vh'}}>
        <div style={{
          flex: '0 0 auto',
          marginBottom: '20px', padding: '0px 11px',
          color: '#000000', fontWeight: 'bold', fontSize: '24px', fontFamily:'Roboto, sans-serif'
        }}>
					Registrati per ricevere il codice di accesso sulla tua email:
        </div>
        <div style={{
          flex: '0 0 auto',
          margin: '0',
          padding: '0px 11px',
          overflowY: 'auto',
          // backgroundColor: 'lightyellow',
        }}>
          <form>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>

              <div style={{display: 'flex', flexDirection: 'column', width: '48%', marginBottom: touched.name && !!errors.name ? '-2px' : '20px'}}>

                <label style={{fontSize: '14px', color: '#000000', fontFamily:'Roboto, sans-serif'}}>Nome</label>
                <TextField
                  id="name"
                  value={!!values.name ? values.name : ''}
                  onBlur={handleBlur}
                  onChange={event => setFieldValue('name', event.target.value)}
                  helperText={touched.name && !!errors.name ? errors.name : ''}
                  variant="outlined"
                  margin="dense"
                  autoComplete="off"
                  style={{width: '100%', marginTop: '3px', borderRadius:'0px !important'}}
                  error={touched.name && !!errors.name}
                />
              </div>

              <div style={{display: 'flex', flexDirection: 'column', width: '48%', marginBottom: touched.surname && !!errors.surname ? '-2px' : '20px'}}>
                <label style={{fontSize: '14px', color: '#000000', fontFamily:'Roboto, sans-serif'}}>Cognome</label>
                <TextField
                  id="surname"
                  value={!!values.surname ? values.surname : ''}
                  onBlur={handleBlur}
                  onChange={event => setFieldValue('surname', event.target.value)}
                  helperText={touched.surname && !!errors.surname ? errors.surname : ''}
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  style={{width: '100%', marginTop: '3px', borderRadius:'0px !important'}}
                  error={touched.surname && !!errors.surname}
                />

              </div>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>

              <div style={{display: 'flex', flexDirection: 'column', width: '48%', marginBottom: touched.company && !!errors.company ? '-2px' : '20px'}}>

                <label style={{fontSize: '14px', color: '#000000',fontFamily:'Roboto, sans-serif'}}>Azienda (opzionale)</label>
                <TextField
                  id="company"
                  value={!!values.company ? values.company : ''}
                  onBlur={handleBlur}
                  onChange={event => setFieldValue('company', event.target.value)}
                  helperText={touched.company && !!errors.company ? errors.company : ''}
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  style={{width: '100%', marginTop: '3px', borderRadius:'0px !important'}}
                  error={touched.company && !!errors.company}
                />
              </div>

              <div style={{display: 'flex', flexDirection: 'column', width: '48%', marginBottom: touched.email && !!errors.email ? '-2px' : '20px'}}>
                <label style={{fontSize: '14px', color: '#000000',fontFamily:'Roboto, sans-serif'}}>Email</label>
                <TextField
                  id="email"
                  value={!!values.email ? values.email : ''}
                  onBlur={handleBlur}
                  onChange={event => setFieldValue('email', event.target.value)}
                  helperText={touched.email && !!errors.email ? errors.email : ''}
                  margin="dense"
                  autoComplete="off"
                  variant="outlined"
                  style={{width: '100%', marginTop: '3px', borderRadius:'0px !important'}}
                  error={touched.email && !!errors.email}
                />

              </div>
            </div>


            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '48%',
                marginBottom: touched.isAccepted && !!errors.isAccepted ? '19px' : '10px'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isAccepted"
                      checked={values.isAccepted}
                      onChange={event => {
                        setFieldValue('isAccepted', event.currentTarget.checked)
                        setTimeout(()=>{handleBlur(event)}, 200)
                      }}
                      style={values.isAccepted ? {color:'#27CCC1', backgroundColor: 'transparent' } : {color: touched.isAccepted && !!errors.isAccepted ? '#f44336' : '', backgroundColor: 'transparent' }}
                    />
                  }
                  label={<span style={{ fontSize: '14px',
                    color: touched.isAccepted && !!errors.isAccepted ? '#f44336' : '#000000', fontFamily:'Roboto, sans-serif' }}>
                    Accetto le condizioni relative alla <a target="_blank" href="https://nuovo.ecofuturo.eu/privacy-policy-exco/" >Privacy Policy</a>
                  </span>}
                />
                  {touched.isAccepted && !!errors.isAccepted &&
                    <FormHelperText style={{marginTop: '-8px', marginLeft: '15px'}} error={touched.isAccepted && !!errors.isAccepted}>{errors.isAccepted}</FormHelperText>
                  }
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isSecondAccepted"
                        checked={values.isSecondAccepted}
                        onChange={event => {
                          setFieldValue('isSecondAccepted', event.currentTarget.checked)
                          setTimeout(()=>{handleBlur(event)}, 200)
                        }}
                        style={values.isSecondAccepted ? {color:'#27CCC1', backgroundColor: 'transparent' } : {color: touched.isSecondAccepted && !!errors.isSecondAccepted ? '#f44336' : '', backgroundColor: 'transparent' }}
                      />
                    }
                    label={<span style={{ fontSize: '14px',
                      color: touched.isSecondAccepted && !!errors.isSecondAccepted ? '#f44336' : '#000000', fontFamily:'Roboto, sans-serif' }}>
                    Consento a comunicare i dati ai partner di Ecofuturo per finalità pubblicitarie e di marketing. <a target="_blank" href="https://nuovo.ecofuturo.eu/termini/" >Termini</a>
                  </span>}
                  />
                  {touched.isSecondAccepted && !!errors.isSecondAccepted &&
                    <FormHelperText style={{marginTop: '-8px', marginLeft: '15px'}} error={touched.isSecondAccepted && !!errors.isSecondAccepted}>{errors.isSecondAccepted}</FormHelperText>
                  }
              </div>

              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '48%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '20px'}}>
                  <Button style={{color: 'white', backgroundColor:submitDisabled ? '#9e7986' : '#E91E63', width: '50%'}}
                          disabled={submitDisabled}
                          variant={"contained"}
                          onClick={handleSubmitForm}
                          type='button'>
                    {!customerState.isFetching && 'Registrati'}
                            {customerState.isFetching && <CircularProgress style={{color:'white'}}/>}
                  </Button>
                </div>
              </div>

            </div>

          </form>
        </div>
      </div>
    </span>
  );
};


const registrationForm = withFormik<IProps, ICustomerDoc>({
  enableReinitialize: true,
  validateOnChange: false,

  mapPropsToValues: (props): ICustomerDoc => {
    return props.customerState.data;
  },

  validate: (values: ICustomerDoc) => {
    return validate(values, false);
  },

  handleSubmit: (values: ICustomerDoc, {props, setSubmitting, setErrors}) => {
    setSubmitting(true);

    const errors = validate(values, true);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);
    } else {
      //const doc = new CustomerModel(values);

      props.actCustomerPost(values);
      setSubmitting(false);
    }
  }
})(form);

const mapStateToProps = (state: IApplicationState) => ({
  customerState: state.customerState,
  modalState: state.modalState
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>) => ({
  actCustomerPost: (obj: ICustomerDoc) => dispatch(actCustomer.postCustomer(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(registrationForm)
