import {ECustomersActionTypes, ICustomersAction, ICustomersState} from './index';
import {Reducer} from 'redux';
import {EDirection, IPaginationParam, ISortParam} from "../../../helper/queryParam/types";

const defaultOrderParams: ISortParam[] = [
	{
		field: 'createdAt',
		dir: EDirection.ASC,
	},
];

const defaultPaginationParams: IPaginationParam = {
	page: 1,
	limit: 20,
};

// Reducer initial State with ALL objects
export const initialState: ICustomersState = {
	data: 				[],
	isFetching: 	false,
	params: 			{
		queryParams: [],
		extraQuery: [],
		sortParams: defaultOrderParams,
		paginationParams: defaultPaginationParams,
	},
	count:				0,
	total:				0,
	page:					0,
	pageCount:		0,
};

const reducer: Reducer<ICustomersState> = (state: ICustomersState = initialState, action) => {
	switch (action.type) {
		case ECustomersActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ECustomersActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ECustomersActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as CustomersReducer }
