import {IUserDoc} from "../store/auth/user";
import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';
import {IRoleDoc} from "../store/auth/role";
import RoleModel from "./roleModel";
import {classToPlain} from "class-transformer";


export default class UserModel implements IUserDoc {

  public id?: 						string | null;
  public email:					  string;
  public isActive:			  boolean;
  public password:				string | undefined;
  public maxSessions:		  number;

  // In relations
  public _delete?:	  		boolean;
  // Typescript
  public createdAt?:	    Date;
  public updatedAt?:	    Date;
  public deletedAt?:	    Date | null;

  // Relation
  public role:           IRoleDoc | null

  // 4 Attach ONLY 1
  // public attach:        IAttach;

  constructor(doc?: IUserDoc) {
    // Initial Value with input object (optional)
    this.id =             !!doc && doc.id ? doc.id : null;
    this.email =					!!doc && doc.email ? doc.email : '';
    this.isActive =       !!doc && doc.isActive ? doc.isActive : false;
    this.password =       !!doc && !!doc.password ? doc.password : '';
    this.maxSessions =	  !!doc && !!doc.maxSessions ? doc.maxSessions : 0;

    this._delete =        !!doc && !!doc._delete ? doc._delete : false;
    this.createdAt =		  !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		  !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =	  	!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;

    // Relations
    this.role =           !!doc && !!doc.role ? new RoleModel(doc.role) : null;

    // 4 Attach
    // this.attach =       !!doc && doc.attach ? AttachModel.toDB2(doc.attach) : AttachModel.toDB2(new AttachModel());
    // this.attach = {
    //   file:   !!doc && doc.attach && doc.attach.file ? doc.attach.file : null,
    //   attach: !!doc && doc.attach && doc.attach.attach ? doc.attach.attach : null,
    // }
  }

  // Object to DB
  static toDB(doc: IUserDoc): IUserDoc {
    const obj: IUserDoc = classToPlain<IUserDoc>(doc) as IUserDoc;
    if (!obj.id) {
      delete obj.id;
    }

    if (!obj._delete) {
      delete obj._delete;
    }

    delete obj.createdAt;
    delete obj.updatedAt;
    delete obj.deletedAt;

    return obj;
  }

  // Object to DB
  static toDB2(doc: IUserDoc): IUserDoc {
    const docForDB = Object.assign({}, doc);
    if (!doc.id) {
      docForDB.id = null;
    }
    if (!doc.password) {
      delete docForDB.password
    }

    // if (!!doc.siteUserPhoto) {
    //   docForDB.siteUserPhoto = UserPhotoModel.toDB2(doc.siteUserPhoto);
    // }
    // else {
    //   delete docForDB.siteUserPhoto
    // }

    delete docForDB.createdAt;
    delete docForDB.updatedAt;
    delete docForDB.deletedAt;

    // delete docForDB.attach;

    return docForDB;
  }


  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      email: {
        name:     'email',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      firstName: {
        name:     'first name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      lastName: {
        name:     'last name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      isActive: {
        name:      'is active',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.BOOLEAN,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      currentLoginAt: {
        name:      'current login at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      lastLoginAt: {
        name:      'last login at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      currentLoginIP: {
        name:      'current login IP',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      lastLoginIP: {
        name:      'last login IP',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      fullName: {
        name:      'full name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'authUser',
      plural:   'authUsers',
    }
  }

}
