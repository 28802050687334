import {ECustomerActionTypes, ICustomerAction, ICustomerDoc, IModalAction} from "./index";
import { Action } from "redux";
import { batch } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import CustomerModel from "../../../model/customerModel";
import {checkToken} from "../../../helper/token/auth_helper";
import axios from "axios";
import {config} from "../../../appConfig";
import {IAttach} from "../../types";
import {IGetTokenReturn} from "../../../helper/token/types";
import {actCustomers} from "../customers";
import {push} from "connected-react-router";
import {actAuth} from "../../auth/auth";


export const actCustomer = {
	init: () => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			getState: () => IApplicationState
		) => {
			const localDoc = new CustomerModel();
			const localPlain = CustomerModel.toDB(localDoc);
			dispatch(actionRequestOn(ECustomerActionTypes.INIT_MODEL, localPlain));
		}
	},

	create: (doc: ICustomerDoc, attach?: IAttach) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			getState: () => IApplicationState
		) => {
			const localPlain = CustomerModel.toDB(doc);
			dispatch(actionRequestOn(ECustomerActionTypes.POST_REQUEST, localPlain));

			const customersState = getState().customersState;
			const params = !!customersState && !!customersState.params ? customersState.params : {};
			const token: IGetTokenReturn = await checkToken();

			console.log('Customer State: ', customersState.params);
			console.log('Customer Params: ', params);

			if (token.accToken) {
				try {
					const url = `${config.site.apiURL}/api/enty/customers`;
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.post<ICustomerDoc>(url, localPlain);
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ECustomerActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ECustomerActionTypes.POST_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actCustomers.get(params));
				dispatch(actionRequestOff(ECustomerActionTypes.POST_SUCCESS, doc));
			});
		}
	},

	postCustomer: (doc: ICustomerDoc, attach?: IAttach) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			getState: () => IApplicationState
		) => {
			const localPlain = CustomerModel.toDB(doc);
			const localDoc = new CustomerModel(doc);
			dispatch(actionRequestOn(ECustomerActionTypes.POST_REQUEST, localDoc));

			const customersState = getState().customersState;
			const params = !!customersState && !!customersState.params ? customersState.params : {};

			console.log('Customer State: ', getState().customerState);
			console.log('Customer Params: ', params);

			try {
				const url = `${config.site.apiURL}/api/enty/customers/formPost`;
				console.log('Customer Post URL: ', url);
				console.log('Customer Post Data: ', localPlain);
				await axios.post<ICustomerDoc>(url, localPlain);
			}
			catch (error) {
				console.log('error: ', error.response);
				if (error.response && error.response.status === 401) {
					console.log('Msg: ', error);
				}
				else if (error.response && error.response.status === 403) {
					console.log('Msg: ', error);
				}
				else if (error.response && error.response.status === 400) {
					console.log('Msg: ', error);
				}
				else{
					console.log('Msg: ', error);
				}
				return batch(() => {
					dispatch(actionRequestOff(ECustomerActionTypes.POST_FAILURE, localDoc));
					dispatch(actModal.openErrorModal(ECustomerActionTypes.OPEN_ERROR_MODAL));
				})
			}
			batch(() => {
				dispatch(actModal.openSuccessfullyModal(ECustomerActionTypes.OPEN_SUCCESS_MODAL));

				dispatch(actionRequestOff(ECustomerActionTypes.POST_SUCCESS, new CustomerModel()));
			});
		}
	},

	update: (doc: ICustomerDoc, attach?: IAttach) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			getState: () => IApplicationState
		) => {
			const localPlain = CustomerModel.toDB(doc);
			dispatch(actionRequestOn(ECustomerActionTypes.PUT_REQUEST, localPlain));

			const customersState = getState().customersState;
			const params = !!customersState && !!customersState.params ? customersState.params : {};
			const token: IGetTokenReturn = await checkToken();
			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.put<ICustomerDoc>(`${config.site.apiURL}/api/enty/customers/${doc.id}`, localPlain);
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ECustomerActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ECustomerActionTypes.PUT_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actCustomers.get(params));
				dispatch(actionRequestOff(ECustomerActionTypes.PUT_SUCCESS, doc));
			});
		}
	},

	get: (doc?: ICustomerDoc, id?: number) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>
		) => {   //, getState: Function
			dispatch(actionRequestOn(ECustomerActionTypes.GET_REQUEST));

			const getDoc: ICustomerDoc = !!doc ? doc : new CustomerModel();

			if (!!doc) {
				return dispatch(actionRequestOff(ECustomerActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const response = await axios.get<ICustomerDoc>(`${config.site.apiURL}/api/enty/customers/${id}` );

					return dispatch(actionRequestOff(ECustomerActionTypes.GET_SUCCESS, response.data));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ECustomerActionTypes.GET_FAILURE, getDoc));
				}
			}

			return dispatch(actionRequestOff(ECustomerActionTypes.GET_FAILURE, getDoc));
		}
	},

	delete: (doc: ICustomerDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(ECustomerActionTypes.DELETE_REQUEST, doc));

			const customersState = getState().customersState;
			const params = !!customersState && !!customersState.params ? customersState.params : {};
			const token: IGetTokenReturn = await checkToken();
			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.delete(`${config.site.apiURL}/api/enty/customers/${doc.id}`,{ data: { "$hardDelete": false }});
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(ECustomerActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(ECustomerActionTypes.DELETE_FAILURE));
					dispatch(actAuth.logout());
				});

			}
			batch(() => {
				dispatch(actCustomers.get(params));
				dispatch(actionRequestOff(ECustomerActionTypes.DELETE_SUCCESS, doc));
			});
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: ECustomerActionTypes, doc?: ICustomerDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: ICustomerAction = {
			type: type,
			payload: {
				data: !!doc ? doc : new CustomerModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: ECustomerActionTypes, doc?: ICustomerDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: ICustomerAction = {
			type: type,
			payload: {
				data: !!doc ? doc : new CustomerModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};

export const actModal = {
	openSuccessfullyModal: (type: ECustomerActionTypes) => {
		return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
			const action: IModalAction = {
				type: type,
				payload: {
					isOpen: true,
					isError: false,
				},
			};
			dispatch(action);

			action.payload.isOpen = false
			setTimeout(()=>{dispatch(action)}, 5000)
		}
	},
	openErrorModal: (type: ECustomerActionTypes) => {
		return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
			const action: IModalAction = {
				type: type,
				payload: {
					isOpen: true,
					isError: true,
				},
			};
			dispatch(action);

			action.payload.isOpen = false
			setTimeout(()=>{dispatch(action)}, 4000)
		}
	}

}


