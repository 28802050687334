export interface IRoleDoc {
	id?: 						string | null,
	name:						string,
	description:		string | null,
	level:					number,

	createdAt?:		Date,
	updatedAt?:		Date,
	deletedAt?:		Date | null,
	// In relations
	_delete?:			boolean,

}

export interface IRoleState {
	readonly doc: 				IRoleDoc,
	readonly isFetching: 	boolean,

}

export interface IRoleAction {
	type: ERoleActionTypes,
	payload: {
		doc?: IRoleDoc,
		isFetching?: boolean
	},
}

export enum ERoleActionTypes {
	GET_REQUEST = '@@role/GET_REQUEST',
	GET_SUCCESS = '@@role/GET_SUCCESS',
	GET_FAILURE = '@@role/GET_FAILURE',
	POST_REQUEST = '@@role/POST_REQUEST',
	POST_SUCCESS = '@@role/POST_SUCCESS',
	POST_FAILURE = '@@role/POST_FAILURE',
	PUT_REQUEST = '@@role/PUT_REQUEST',
	PUT_SUCCESS = '@@role/PUT_SUCCESS',
	PUT_FAILURE = '@@role/PUT_FAILURE',
	DELETE_REQUEST = '@@role/DELETE_REQUEST',
	DELETE_SUCCESS = '@@role/DELETE_SUCCESS',
	DELETE_FAILURE = '@@role/DELETE_FAILURE',
}
