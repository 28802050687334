import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import {IApplicationState} from "../store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {Button, Card, CardContent, TextField} from "@material-ui/core";
import {actAuth, IAuthState, ILoginParams} from "../store/auth/auth";
import {
  isMobile
} from "react-device-detect";

// interface Props {}

// Props passed from mapStateToProps TS
interface PropsFromState {
  authState?: IAuthState
}

interface PropsFromDispatch {
  actAuthLogin: (param: ILoginParams) => void,
  actAuthLogOut: () => void,
}

type IProps = PropsFromState & PropsFromDispatch ;


function LoginForm(props: PropsFromDispatch) {
  const [param, setParam] = useState({email:'', password:''});
  const [number, setNumber] = useState<string>('000');

  useEffect(() => {
    const number = Math.floor(Math.random() * 40).toString().padStart(3, '0');
    setNumber(number);
    console.log('Number: ', number);
  }, []);

  const getBackground = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const photo = require(`../assets/images/background/${number}.jpg`);
    return photo.default;
  }

  return (
    <div className="classes.backImage" style={{paddingTop: '50px', display: "flex", justifyContent: "center",
      minHeight: 'calc(100vh - 50px)', backgroundImage: `url(${getBackground()})`,
      backgroundSize: 'cover'}}>

      <div style={{width: '400px', opacity: 0.90, margin: isMobile ? '10px' : 0}}>
        <Card style={{padding: '20px'}} elevation={10} square={false}>
          <CardContent>
            <h2 style={{textAlign: "center"}}>Accedi all'area riservata</h2>

            <form>
              <TextField
                id="email"
                label="Email"
                value={param.email}
                onChange={(e) => setParam({email:e.target.value, password:param.password})}
                margin="normal"
                fullWidth
              />
              <TextField
                id="password"
                label="Password"
                value={param.password}
                onChange={(e) => setParam({email:param.email, password:e.target.value})}
                margin="normal"
                type="password"
                fullWidth
              />

              <div style={{display:"flex",justifyContent:"center",paddingTop:"33px"}}>
                <Button type="submit" variant="contained" color="primary" style={{color: 'white'}} onClick={(e) => {e.preventDefault();props.actAuthLogin(param)}}>
                  Log In
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>


      </div>

    </div>
  );
}


class LogIn extends React.Component<IProps> {

  componentDidMount = () => {
    // this.props.actAuthInit();
  };

  render () {
    // console.log('Login Props: ', this.props);
    const { authState } = this.props;
    if(!!authState && authState.isAuthenticated) {
      //Router.push('/');
    }
    return (
      <span >
        <div>
          <LoginForm {...this.props}/>
        </div>
      </span>
    )
  }
}


const mapStateToProps = (state: IApplicationState): PropsFromState => ({
  authState:  state.authState,
});

// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  //AUTH Actions
  actAuthLogOut: () => dispatch(actAuth.logout()),
  actAuthLogin: (param: ILoginParams) => dispatch(actAuth.login(param)),
  //AUTH Actions
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
