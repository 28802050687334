import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography} from "@material-ui/core";
import {IItemMenu, primaryMenu} from "../../routes/RoutesList";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {IProps} from "../layout";
import logo from "../../assets/images/yn_logo.png";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
	useWindowWidth,
} from '@react-hook/window-size'
import { useHistory } from 'react-router-dom';


export function LODrawer(props: IProps) {
	const {layoutState} = props;

	const history = useHistory();
	const classes = useStyles(layoutState.drawerDim, layoutState.isMobile, layoutState.isViewSmall)();
	const onlyWidth = useWindowWidth();
	const [menuItem, setMenuItem] = useState<IItemMenu>();
	const [submenuItem, setSubmenuItem] = useState<IItemMenu | null>();
	const [menuOpen, setMenuOpen] = useState<IItemMenu | null>();

	useEffect(() => {
		const location = props.router.location?.pathname;
		console.log('Drw Menu: ', menuItem);
		if (!!location && !menuItem && !submenuItem ) {
			primaryMenu.map((value: IItemMenu) => {
				if (value.route === location) {
					setMenuItem(value);
					props.actLayoutToggleTitle(value.key);
					console.log('Drw Menu: ', value);
				}
				value.submenu.map((subItem: IItemMenu) => {
					if (subItem.route === location) {
						setSubmenuItem(subItem);
						setMenuItem(value);
						setMenuOpen(value);
						props.actLayoutToggleTitle(value.key + ' - ' + subItem.key);
						console.log('Drw Menu: ', value);
						console.log('Drw SubMenu: ', subItem);
					}
				});
			});
		}
		console.log('Drw Loc: ', location);

	}, [])

	useEffect(() => {
		props.actLayoutToggleSize(onlyWidth);
	}, [onlyWidth])

	const selectMenuItem = (item: IItemMenu): void => {
		console.log('Drw Select: ', item);
		if (!!item && item.submenu.length) {
			setMenuOpen(item);
		} else {
			if (!!item.route) {
				props.actLayoutToggleTitle(item.key);
				setMenuItem(item);
				setSubmenuItem(null);
				history.push(item.route);
			}
			setMenuOpen(null);
		}
	}

	const selectSubmenuItem = (item: IItemMenu): void => {
		console.log('Drw SubSelect: ', item);
		if (!!item && !!item.route) {
			setSubmenuItem(item);
			props.actLayoutToggleTitle(menuOpen?.key + ' - ' + item.key);
			history.push(item.route);
			if (!!menuOpen) {
				setMenuItem(menuOpen);
			}
		}
	}

	return(
		<Drawer
			className={classes.drawer}
			variant={layoutState.isWeb && !layoutState.isViewSmall ? "persistent" : "temporary"}
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor="left"
			open={layoutState.isWeb && !layoutState.isViewSmall ? true : layoutState.isDrwOpen}
		>
			{/*<Toolbar />*/}
			<div className={classes.drawerContainer}
			     style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
				<div>
					{(layoutState.isMobile || layoutState.isViewSmall) &&
          <div style={{height: '50px', display: 'flex', justifyContent: 'flex-end', padding: '15px'}}>
            <ArrowBackIosIcon onClick={() => props.actLayoutToggleOpen()}/>
          </div>
					}
					<List>
						{(layoutState.isMobile || layoutState.isViewSmall) &&
            <Divider/>
						}
						{primaryMenu.map((value: IItemMenu) => (
							<ListItem button key={value.key}
							          selected={value.key === menuItem?.key}
							          disabled={value.key === menuItem?.key}
							          onClick={() => selectMenuItem(value)}
							>
								<Tooltip title={value.key} aria-label={value.key} disableHoverListener={layoutState.isDrwOpen}>
									<ListItemIcon> {value.icon} </ListItemIcon>
								</Tooltip>
								<ListItemText primary={value.key}/>
								{value.key === menuOpen?.key &&
                <ArrowDropDownIcon />
								}
							</ListItem>


						))}
					</List>

					<Divider />
					<List>
						{!!menuOpen && menuOpen.submenu.map((value: IItemMenu) => (
							<ListItem dense button key={value.key}
							          selected={value.key === submenuItem?.key}
							          disabled={value.key === submenuItem?.key}
							          onClick={() => selectSubmenuItem(value)}
							>
								<Tooltip title={value.key} aria-label={value.key} disableHoverListener={layoutState.isDrwOpen}>
									<ListItemIcon> {value.icon} </ListItemIcon>
								</Tooltip>
								<ListItemText primary={value.key}/>
							</ListItem>
						))}
					</List>
				</div>
				{props.layoutState.isDrwOpen ?
					<div style={{marginBottom: '10px'}}>
						<Typography align={'center'} variant="subtitle2" style={{fontSize: '0.70rem', lineHeight: '1.20', fontStyle: 'italic'}}>
							Powered by
						</Typography>
						<Typography align={'center'} variant="subtitle1" >
							YooNo Lab Srl
						</Typography>
					</div>
					:
					<div style={{marginBottom: '10px', marginLeft: '1px', marginRight: '1px', textAlign: 'center', width: '100%'}}>
						<Tooltip title={'Powered by YooNo Lab'} aria-label={'Powered by YooNo Lab'} >
							<img className={classes.yn_logo} src={logo} alt={"YooNo Lab"}/>
						</Tooltip>
					</div>

				}

			</div>
		</Drawer>
	)
}
