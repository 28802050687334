import {EUsersActionTypes, IUsersState} from './index';
import {Reducer} from 'redux';

// Reducer initial State with ALL objects
export const initialState: IUsersState = {
	data: 				[],
	isFetching: 	false,
	params: 			{},
	count:				0,
	total:				0,
	page:					0,
	pageCount:		0,
};

const reducer: Reducer<IUsersState> = (state: IUsersState = initialState, action) => {
	switch (action.type) {
		case EUsersActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EUsersActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case EUsersActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as UsersReducer }
