import {AppBar, Button, IconButton, Toolbar, Typography} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import {useStyles} from "./style";
import {IProps} from "../layout";


export function LOAppBar(props: IProps) {
	const {layoutState} = props;
	const classes = useStyles(layoutState.drawerDim, layoutState.isMobile, layoutState.isViewSmall)();

	return(
		<div style={{margin: '15px'}}>
			<AppBar className={classes.appBar} position="relative" color={'primary'} >
				<Toolbar>
					<IconButton edge="start" className={classes.menuButton}
					            color="inherit" aria-label="menu"
					            onClick={() => props.actLayoutToggleOpen()}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{layoutState.title}
					</Typography>
					<Button color="inherit">Login</Button>
				</Toolbar>
			</AppBar>
		</div>
	)
}
