import {createStyles, makeStyles, Theme} from "@material-ui/core";


export const useStyles = (dim: number, isMobile: boolean, isViewSmall: boolean) => makeStyles((theme: Theme) => {
	const realMargin = isMobile || isViewSmall ? 0 : dim;
	return (
		createStyles({
			root: {
				flexGrow: 1,
				display: 'flex',
			},
			appBar: {
				zIndex: isMobile || isViewSmall ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1,
				height: '65px',
				width: 'calc(100vw - 30px)',
				paddingLeft: '15px',
				opacity: 0.95,
				borderRadius: 10,
				backgroundColor: theme.palette.primary.light,
			},
			menuButton: {
				marginRight: theme.spacing(2),
				cursor: 'pointer',
			},
			title: {
				flexGrow: 1,
			},
			drawer: {
				// width: 0,
				flexShrink: 0,
			},
			drawerPaper: {
				borderRadius: 10,
				width: dim,
				height: isMobile || isViewSmall ? 'calc(100vh - 30px)' : 'calc(100vh - 110px)',
				backgroundColor: theme.palette.grey.A100,
				marginTop: isMobile || isViewSmall ? '15px' : '95px',
				marginRight: '15px',
				marginBottom: '15px',
				marginLeft: '15px',
				opacity: 0.95,
				color: theme.palette.grey["900"],
			},
			drawerContainer: {
				overflow: 'hidden',
				height: '100%',
			},
			content: {
				flexGrow: 1,
				padding: theme.spacing(3),
			},
			bodyPaper: {
				position: 'relative',
				// zIndex: theme.zIndex.drawer + 3,
				// top: '100vh',
				// left: 'calc(260px - 100vw)',
				borderRadius: 10,
				width: !!realMargin ? `calc(100vw - ${realMargin}px - 75px)` : `calc(100vw - 60px)`,
				height: 'calc(100vh - 140px)',
				backgroundColor: theme.palette.grey.A200,
				marginTop: 0,
				marginLeft: !!realMargin ? realMargin + 30 : 15,
				marginRight: 0,
				marginBottom: 15,
				opacity: 0.97,
				color: theme.palette.grey["900"],
				padding: '15px',
			},
			yn_logo: {
				width: '100%',
				backgroundSize: 'cover',
			}
		})
	)
}

);
