import {ICustomerDoc} from "../store/enty/customer";
import {ERoles, ETypes, IMUtil, ITableName, SerializableObject} from "./types";
import { isDate } from 'date-fns';
import {classToPlain, plainToClass} from 'class-transformer';
import {IUserDoc} from "../store/auth/user";
import UserModel from "./userModel";

export default class CustomerModel implements  ICustomerDoc{

  public id?:					    string | null;
  public email:					  string;
  public username:	      string;
  public ticket:					string;
  public name:					  string;
  public surname:					string;
  public company:					string;
  public gender:					string;
  public telephone:				string;
  public _delete:         boolean;
  public createdAt?:	    Date;
  public updatedAt?:	    Date;
  public deletedAt?:	    Date | null;
  // Extra
  public isAccepted:      boolean;
  public isSecondAccepted: boolean;
  public reCaptchaToken?:  string;

  constructor(doc?: ICustomerDoc) {
    this.id = !!doc && doc.id ? doc.id : null;
    this.email =	!!doc && doc.email ? doc.email : '';
    this.username = !!doc && doc.username ? doc.username : '';
    this.ticket = !!doc && !!doc.ticket ? doc.ticket : '';
    this.name =	!!doc && !!doc.name ? doc.name : '';
    this.surname = !!doc && !!doc.surname ? doc.surname : '';
    this.company =	!!doc && !!doc.company ? doc.company : '';
    this.gender =	!!doc && !!doc.gender ? doc.gender : '';
    this.telephone =	!!doc && !!doc.telephone ? doc.telephone : '';
    this._delete = !!doc && !!doc._delete ? doc._delete : false;
    this.createdAt = !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt = !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt = !!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;
    this.isAccepted = !!doc && doc.isAccepted ? doc.isAccepted : false;
    this.isSecondAccepted = !!doc && doc.isSecondAccepted ? doc.isSecondAccepted : false;
    this.reCaptchaToken = !!doc && doc.reCaptchaToken ? doc.reCaptchaToken : '';
  }

  // Object to DB
  static toDB(doc: ICustomerDoc): ICustomerDoc {
    const obj: ICustomerDoc = classToPlain<ICustomerDoc>(doc) as ICustomerDoc;

    console.log('Customer Model: ', obj);

    if (!obj.id) {
      delete obj.id;
    }
    if (!obj.reCaptchaToken) {
      delete obj.reCaptchaToken;
    }
    if (!obj._delete) {
      delete obj._delete;
    }

    delete obj.createdAt;
    delete obj.updatedAt;
    delete obj.deletedAt;

    // console.log('Customer Model: ', obj);
    return obj;
  }

  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      email: {
        name:     'email',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      username: {
        name:     'username',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      ticket: {
        name:     'ticket',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      name: {
        name:      'name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.BOOLEAN,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      surname: {
        name:      'surname',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      company: {
        name:      'company',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      gender: {
        name:      'gender',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      telephone: {
        name:      'telephone',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'authCustomer',
      plural:   'authCustomers',
    }
  }

}
