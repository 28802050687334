import {useStyles} from "./style";
import {Typography} from "@material-ui/core";
import React from "react";
import {IProps} from "../layout";
import {Routes} from "../../routes/RoutesList";
import Dashboard from "../dashboard";


export function LOBody(props: IProps) {
	const {layoutState} = props;
	const classes = useStyles(layoutState.drawerDim, layoutState.isMobile, layoutState.isViewSmall)();

	return(
		<div className={classes.bodyPaper}>
			{props.children}
		</div>
	)

}
