import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history';
import { AuthReducer, IAuthState } from "./auth/auth";
import { RoleReducer, IRoleState } from "./auth/role/index";
import { RolesReducer, IRolesState } from "./auth/roles/index";
import { UserReducer, IUserState } from "./auth/user/index";
import { UsersReducer, IUsersState } from "./auth/users/index";
import {CustomerReducer, ICustomerState, IModalState, ModalReducer} from "./enty/customer";
import {CustomersReducer, ICustomersState} from "./enty/customers";
import {ILayoutState, LayoutReducer} from "./layout";


// The top-level state object
export interface IApplicationState {
  router:           RouterState,
  authState:        IAuthState,
  customerState:    ICustomerState,
  customersState:   ICustomersState,
  layoutState:      ILayoutState,
  modalState:       IModalState,
  roleState:        IRoleState,
  rolesState:       IRolesState,
  userState:        IUserState,
  usersState:       IUsersState,

}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding Application import { IMunicipalitiesState } from './geo/municipalities/types';onState property type.


export default (history: History): Reducer<IApplicationState> => {
  return combineReducers({
    router:           connectRouter(history),
    // rest of your reducers
    authState:        AuthReducer,
    customerState:    CustomerReducer,
    customersState:   CustomersReducer,
    layoutState:      LayoutReducer,
    modalState:       ModalReducer,
    // languageState:    LanguageReducer,
    roleState:        RoleReducer,
    rolesState:       RolesReducer,
    // sessionState:     SessionReducer,
    // sessionsState:    SessionsReducer,
    userState:        UserReducer,
    usersState:       UsersReducer,

  });
}
