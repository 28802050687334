import { EUserActionTypes, IUserAction, IUserDoc } from "./index";
import { Action } from "redux";
import { batch } from 'react-redux';
import { ThunkDispatch } from "redux-thunk";
import { IApplicationState } from "../../index";
import {checkToken, initToken} from "../../../helper/token/auth_helper";
import {actAuth, IAuthState} from "../auth";
import axios from "axios";
import {config} from "../../../appConfig";
import {IAttach} from "../../types";
import {IGetTokenReturn} from "../../../helper/token/types";
import {actUsers} from "../users";
import {push} from "connected-react-router";
import UserModel from "../../../model/userModel";


export const actUser = {
	create: (doc: IUserDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EUserActionTypes.POST_REQUEST, doc));

			const usersState = getState().usersState;
			const params = !!usersState && !!usersState.params ? usersState.params : {};
			const token: IGetTokenReturn = await checkToken();

			console.log('User State: ', usersState.params);
			console.log('User Params: ', params);

			if (token.accToken) {
				try {
					const url = `${config.site.apiURL}/api/auth/users`;
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.post<IUserDoc>(url, UserModel.toDB2(doc));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(EUserActionTypes.POST_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(EUserActionTypes.POST_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actUsers.get(params));
				dispatch(actionRequestOff(EUserActionTypes.POST_SUCCESS, doc));
			});
		}
	},

	update: (doc: IUserDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EUserActionTypes.PUT_REQUEST, doc));

			const usersState = getState().usersState;
			const params = !!usersState && !!usersState.params ? usersState.params : {};
			const token: IGetTokenReturn = await checkToken();

			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.put<IUserDoc>(`${config.site.apiURL}/api/auth/users/${doc.id}`, UserModel.toDB2(doc));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(EUserActionTypes.PUT_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(EUserActionTypes.PUT_FAILURE));
					dispatch(actAuth.logout());
				});
			}
			batch(() => {
				dispatch(actUsers.get(params));
				dispatch(actionRequestOff(EUserActionTypes.PUT_SUCCESS, doc));
			});
		}
	},

	get: (doc?: IUserDoc, id?: number) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action> ) => {   //, getState: Function
			dispatch(actionRequestOn(EUserActionTypes.GET_REQUEST));
			const getDoc: IUserDoc = !!doc ? doc : new UserModel();

			if (!!doc) {
				return dispatch(actionRequestOff(EUserActionTypes.GET_SUCCESS, getDoc));
			}
			if (!!id) {
				try {
					const response = await axios.get<IUserDoc>(`${config.site.apiURL}/api/users/${id}` );
					return dispatch(actionRequestOff(EUserActionTypes.GET_SUCCESS, response.data));
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(EUserActionTypes.GET_FAILURE));
				}
			}
			return dispatch(actionRequestOff(EUserActionTypes.GET_FAILURE));
		}
	},

	delete: (doc: IUserDoc, attach?: IAttach) => {
		return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => {
			dispatch(actionRequestOn(EUserActionTypes.DELETE_REQUEST, doc));

			const usersState = getState().usersState;
			const params = !!usersState && !!usersState.params ? usersState.params : {};
			const token: IGetTokenReturn = await checkToken();

			if (token.accToken) {
				try {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;
					await axios.delete(`${config.site.apiURL}/api/users/${doc.id}`,{ data: { "$hardDelete": false }});
				}
				catch (error) {
					console.log('error: ', error.response);
					if (error.response && error.response.status === 401) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 403) {
						console.log('Msg: ', error);
					}
					else if (error.response && error.response.status === 400) {
						console.log('Msg: ', error);
					}
					else{
						console.log('Msg: ', error);
					}
					return dispatch(actionRequestOff(EUserActionTypes.DELETE_FAILURE, doc));
				}
			}
			else {
				batch(() => {
					dispatch(push('/'));
					dispatch(actionRequestOff(EUserActionTypes.DELETE_FAILURE));
					dispatch(actAuth.logout());
				});

			}
			batch(() => {
				dispatch(actUsers.get(params));
				dispatch(actionRequestOff(EUserActionTypes.DELETE_SUCCESS, doc));
			});
		}
	},

};

// Service for Fetching
const actionRequestOn = (type: EUserActionTypes, doc?: IUserDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IUserAction = {
			type: type,
			payload: {
				data: !!doc ? new UserModel(doc) : new UserModel(),
				isFetching: true,
			},
		};
		dispatch(action);
	}
};

const actionRequestOff = (type: EUserActionTypes, doc?: IUserDoc) => {
	return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
		const action: IUserAction = {
			type: type,
			payload: {
				data: !!doc ? new UserModel(doc) : new UserModel(),
				isFetching: false,
			},
		};
		dispatch(action);
	}
};
