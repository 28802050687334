import * as React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import * as routes from "./RoutesList";
import {IAuthState} from "../store/auth/auth";
import {IApplicationState} from "../store";
import {ReactElement, ReactNode} from "react";
import Layout from "../pages/layout";

// Container specific Props
interface RoutingProps {
	component: ReactNode,
	path: string,
	exact: boolean,
	history?: History,
	otherProps?: any,
	authState?: IAuthState,
}

const  PrivateRoute: (props: RoutingProps) => ReactElement = (props: RoutingProps): ReactElement => {

	if (props.authState?.isAuthenticated)
		return (
			<Layout >
				<Route history={props.history} {...props.otherProps} component={props.component}/>
			</Layout>
			);
	else {
		return <Redirect to={routes.SIGN_IN}/>;
	}
};


function mapStateToProps(state: IApplicationState) {
	return {
		authState: state.authState
	}
}

export default connect(mapStateToProps, null)(PrivateRoute);
