import * as React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import * as routes from "./RoutesList";
import {IAuthState} from "../store/auth/auth";
import {IApplicationState} from "../store";
import {ReactElement, ReactNode} from "react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

// Props passed from mapStateToProps TS
interface RoutingProps {
	component: ReactNode,
	path: string,
	exact: boolean,
	history?: History,
	otherProps?: any,
	authState?: IAuthState
}

	const PublicRoute: (props: RoutingProps) => ReactElement =
		(props: RoutingProps): ReactElement => {

	console.log('Router: ', props.path);
	if (props.authState?.isAuthenticated) {
		return <Redirect to={routes.HOME}/>;
	} else {
		return(
			<GoogleReCaptchaProvider reCaptchaKey="6LfrXvIZAAAAAIdtABWJOMcuO_032YmSf2X2gWUy">
				<Route history={props.history} {...props.otherProps} component={props.component}/>
			</GoogleReCaptchaProvider>
		)
	}
};


function mapStateToProps(state: IApplicationState) {
	return {
		authState: state.authState
	}
}

export default connect(mapStateToProps, null)(PublicRoute);
