import { Reducer } from 'redux';
import { IAuthState, EAuthActionTypes } from './index';

const initialState: IAuthState = {
	isAuthenticated: 	false,
	isFetching: 			false,
	isInitializing: 	true,
	user: 						null,
	role:             null,
};

const authReducer: Reducer<IAuthState> = (state: IAuthState = initialState, action) => {
	switch (action.type) {
		case EAuthActionTypes.AUTH_INIT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EAuthActionTypes.AUTH_INIT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case EAuthActionTypes.AUTH_INIT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case EAuthActionTypes.AUTH_LOGIN_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EAuthActionTypes.AUTH_LOGIN_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case EAuthActionTypes.AUTH_LOGIN_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case EAuthActionTypes.AUTH_LOGOUT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EAuthActionTypes.AUTH_LOGOUT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case EAuthActionTypes.AUTH_LOGOUT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		//DEFAULT
		default:
			return state;
	}
};

export { authReducer as AuthReducer }
