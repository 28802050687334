import {ERoles, ETypes, IMUtil, ITableName} from "./types";
import { isDate } from 'date-fns';
import {IRoleDoc} from "../store/auth/role";
import {classToPlain} from "class-transformer";


export default class RoleModel implements IRoleDoc {

  public id?: 						string | null;
  public name:					  string;
  public description:			string;
  public level:		        number;

  public _delete?:	  		boolean;

  public createdAt?:	    Date;
  public updatedAt?:	    Date;
  public deletedAt?:	    Date | null;

  constructor(doc?: IRoleDoc) {
    this.id =             !!doc && doc.id ? doc.id : null;
    this.name =					  !!doc && doc.name ? doc.name : '';
    this.description =    !!doc && doc.description ? doc.description : '';
    this.level =          !!doc && !!doc.level ? doc.level : 0;
    this._delete =        !!doc && !!doc._delete ? doc._delete : false;
    this.createdAt =		  !!doc && !!doc.createdAt ? isDate(doc.createdAt) ? doc.createdAt : new Date(doc.createdAt) : new Date();
    this.updatedAt =		  !!doc && !!doc.updatedAt ? isDate(doc.updatedAt) ? doc.updatedAt : new Date(doc.updatedAt) : new Date();
    this.deletedAt =	  	!!doc && !!doc.deletedAt ? isDate(doc.deletedAt) ? doc.deletedAt : new Date(doc.deletedAt) : null;
  }

  // Object to DB
  static toDB(doc: IRoleDoc): IRoleDoc {
    const obj: IRoleDoc = classToPlain<IRoleDoc>(doc) as IRoleDoc;
    if (!obj.id) {
      delete obj.id;
    }

    if (!obj._delete) {
      delete obj._delete;
    }

    delete obj.createdAt;
    delete obj.updatedAt;
    delete obj.deletedAt;

    return obj;
  }

  // STATIC methods
  static util(): IMUtil {
    return {
      id: {
        name:     'id',
        forSearch: {
          forRoles:   [ERoles.SUPER_ADMIN],
          relation:   {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      name: {
        name:     'name',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      description: {
        name:     'description',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.STRING,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      level: {
        name:     'level',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.INTEGER,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      createdAt: {
        name:      'created at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [ERoles.SUPER_ADMIN, ERoles.ADMIN, ERoles.MANAGER, ERoles.EDITOR, ERoles.USER],
          isSortable: true,
        },
      },

      updatedAt: {
        name:      'updated at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

      deletedAt: {
        name:      'deleted at',
        forSearch: {
          forRoles: [ERoles.SUPER_ADMIN, ERoles.ADMIN],
          relation: {},
        },
        type:     ETypes.DATE,
        forTable: {
          visibleFor: [],
          isSortable: false,
        },
      },

    };
  }

  static tableName(): ITableName {
    return {
      singular: 'authUser',
      plural:   'authUsers',
    }
  }

}
