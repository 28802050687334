import React from 'react';
// import logo from './logo.svg';
import './App.css';
import {createMuiTheme, ThemeProvider, Theme, responsiveFontSizes, CircularProgress} from "@material-ui/core";
import {ThunkDispatch} from "redux-thunk";
import 'reflect-metadata';
import {isMobile} from 'react-device-detect';
// import { BrowserRouter } from 'react-router-dom';
import {IApplicationState} from "./store";
import {Action} from "redux";
import {actAuth, IAuthState} from "./store/auth/auth";
import {connect} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import { History } from 'history';
import AppRouter from "./routes/AppRouter";
import {actLayout} from "./store/layout";

const theme = createMuiTheme({
  palette: {
    secondary: { main: '#787293' },
    primary: {
      light: '#4857aa',
      main:  '#303d89',
      dark: '#141d55',
    }
  },

})

const responsiveTheme = responsiveFontSizes(theme);



interface IProps {
  history: History,
}

interface PropsFromState {
  authState: IAuthState,
}

interface PropsFromDispatch {
  authInit: () => void,
  layoutInit: (isMobile: boolean) => void;
}


interface IState {
  showSidebar: boolean,
}

type Props = IProps & PropsFromState & PropsFromDispatch

class App extends React.Component<Props, IState> {
  constructor(props: Props)  {
    super(props);

    this.state = {
      showSidebar: false,
    };

  }

  componentDidMount() {
    this.props.authInit();
    this.props.layoutInit(isMobile);
  }

  render() {
    const { history, authState } = this.props;

    return (
      <ThemeProvider theme={responsiveTheme}>
        <ConnectedRouter history={history}>
          {authState && authState.isFetching || authState.isInitializing ?
            <div style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <CircularProgress size={100} thickness={4.0} color="secondary" />
            </div> :
            <AppRouter/>
          }

        </ConnectedRouter>
      </ThemeProvider>
    );
  }

}

function mapStateToProps(state: IApplicationState) {
  return {
    authState: state.authState,
  };
}


// Mapping our action dispatcher to props is especially useful when creating container components.
const mapDispatchToProps = (dispatch: ThunkDispatch<IApplicationState, void, Action>): PropsFromDispatch => ({
  // Auth Actions
  authInit: () => dispatch(actAuth.init()),
  //Layout Actions
  layoutInit: () => dispatch(actLayout.init(isMobile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
