import {ECustomersActionTypes, ICustomersAction} from "./index";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../../index";
import {EDirection, IPaginationParam, IParamsTypes, ISortParam} from "../../../helper/queryParam/types";
import {config} from "../../../appConfig"
import axios from 'axios';
import {ICustomerDoc} from "../customer";
import {IServerResMany} from "../../types";
import {push} from "connected-react-router";
import {checkToken} from "../../../helper/token/auth_helper";
import {IGetTokenReturn} from "../../../helper/token/types";
// import {IUserDoc} from "../../auth/user";
import CustomerModel from "../../../model/customerModel";
// import {EUsersActionTypes, IUsersAction} from "../../auth/users";
import {actAuth} from "../../auth/auth";
import {batch} from "react-redux";
import {queryParamDecode} from "../../../helper/queryParam/queryParams";


// Default Query
const defaultOrderParams: ISortParam[] = [
  {
    field: 'createdAt',
    dir: EDirection.ASC,
  },
];

const paginationParams4Export: IPaginationParam = {
  page: 1,
  limit: 500,
};

export const actCustomers = {
  get: ({
          queryParams,
          extraQuery,
          sortParams,
          paginationParams
        }: IParamsTypes)  => {
    return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => { // ,	getState: Function

      dispatch(actionRequestOn(ECustomersActionTypes.GET_REQUEST));
      const params: IParamsTypes = {
        queryParams: queryParams ?? getState().customersState.params.queryParams,
        extraQuery: extraQuery ?? getState().customersState.params.extraQuery,
        sortParams: sortParams ?? getState().customersState.params.sortParams,
        paginationParams: paginationParams ?? getState().customersState.params.paginationParams,
      };

      const token: IGetTokenReturn = await checkToken();

      if (token.accToken) {
        try {
          const url = `${config.site.apiURL}/api/enty/customers?`;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;

          const queryAxios = queryParamDecode(params);
          const response = await axios.get<IServerResMany<ICustomerDoc>>(url + queryAxios);

          const docs: ICustomerDoc[] = response.data.data.map((u) => {
            return new CustomerModel(u);
          });

          return dispatch(actionRequestOff(
            ECustomersActionTypes.GET_SUCCESS,
            docs,
            params,
            response.data.count,
            response.data.total,
            response.data.page,
            response.data.pageCount,
            )
          );
        }
        catch (error) {
          console.log('AXIOS error: ', JSON.stringify(error));
          console.log('AXIOS error: ', error);
          if (error.response && error.response.status === 401) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
          } else if (error.response && error.response.status === 403) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
          } else if (error.response && error.response.status === 400) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
          } else {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
          }
          // Close error
          return dispatch(actionRequestOff(ECustomersActionTypes.GET_FAILURE, [], params));
        }
      }
      else {
        batch(() => {
          dispatch(push('/'));
          dispatch(actionRequestOff(ECustomersActionTypes.GET_FAILURE, [], params));
          dispatch(actAuth.logout());
        });
      }
    }
  },

  getExport: ({
          queryParams,
          extraQuery,
          sortParams,
          paginationParams
        }: IParamsTypes)  => {
    return async (dispatch: ThunkDispatch<IApplicationState, void, Action>, getState: () => IApplicationState) => { // ,	getState: Function

      // dispatch(actionRequestOn(ECustomersActionTypes.GET_REQUEST));
      const params: IParamsTypes = {
        queryParams: queryParams ?? getState().customersState.params.queryParams,
        extraQuery: extraQuery ?? getState().customersState.params.extraQuery,
        sortParams: sortParams ?? getState().customersState.params.sortParams,
        paginationParams: paginationParams4Export,
      };

      const token: IGetTokenReturn = await checkToken();

      if (token.accToken) {
        try {
          const url = `${config.site.apiURL}/api/enty/customers?`;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.accToken;

          const queryAxios = queryParamDecode(params);
          const response = await axios.get<IServerResMany<ICustomerDoc>>(url + queryAxios);

          const docs: ICustomerDoc[] = response.data.data.map((u) => {
            return new CustomerModel(u);
          });

          if (response.data.pageCount > 1) {
            for (let x = 2; x <= response.data.pageCount; x++) {
              const params: IParamsTypes = {
                queryParams: queryParams ?? getState().customersState.params.queryParams,
                extraQuery: extraQuery ?? getState().customersState.params.extraQuery,
                sortParams: sortParams ?? getState().customersState.params.sortParams,
                paginationParams: {
                  page: x,
                  limit: 500,
                },
              };
              const queryAxios = queryParamDecode(params);
              const response = await axios.get<IServerResMany<ICustomerDoc>>(url + queryAxios);

              const others: ICustomerDoc[] = response.data.data.map((u) => {
                return new CustomerModel(u);
              });
              docs.push(...others);
            }
          }

          return(docs);

          // return dispatch(actionRequestOff(
          //   ECustomersActionTypes.GET_SUCCESS,
          //   )
          // );
        }
        catch (error) {
          console.log('AXIOS error: ', JSON.stringify(error));
          console.log('AXIOS error: ', error);
          if (error.response && error.response.status === 401) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON AUTORIZZATO'}}});
          } else if (error.response && error.response.status === 403) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {code: null, message:'ACCESSO NON CONSENTITO'}}});
          } else if (error.response && error.response.status === 400) {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
          } else {
            //dispatch({type: SNACKBAR_ERROR, payload: {serverError: {path: error.response.data.details[0].path, message:error.response.data.details[0].path + ' ' + error.response.data.details[0].message, type:error.response.data.details[0].type}}});
          }
          // Close error
          // return dispatch(actionRequestOff(ECustomersActionTypes.GET_FAILURE, [], params));
        }
      }
      else {
        batch(() => {
          dispatch(push('/'));
          dispatch(actionRequestOff(ECustomersActionTypes.GET_FAILURE, [], params));
          dispatch(actAuth.logout());
        });
      }
    }
  },
};

// Service for Fetching
const actionRequestOn = (type: ECustomersActionTypes) => {
  return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
    const action: ICustomersAction = {
      type: type,
      payload: {
        // data: [],
        isFetching: true,
      },
    };
    dispatch(action);
  }
};

const actionRequestOff = (
  type: ECustomersActionTypes,
  data: ICustomerDoc[],
  params: IParamsTypes,
  count?: number,
  total?: number,
  page?: number,
  pageCount?: number,
) => {
  return (dispatch: ThunkDispatch<IApplicationState, void, Action>) => {
    const action: ICustomersAction = {
      type: type,
      payload: {
        params: !!params ? params : {},
        isFetching: false,
        data: !!data ? data : [],
        count: !!count ? count : null,
        total: !!total ? total : null,
        page: !!page ? page : null,
        pageCount: !!pageCount ? pageCount : null,
      },
    };
    dispatch(action);
  }
};

