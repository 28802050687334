import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import * as routes from './RoutesList';
import LogIn from "../pages/login";
import RegistrationForm from "../pages/registrationForm";
import Layout from "../pages/layout";
import Dashboard from "../pages/dashboard";
import Customers from "../pages/customers";
import RegistrationFormNew from "../pages/registrationFormNew";


const NoMatch = () => (
	<Layout />
);


const AppRouter = () => {
	return (

		<Switch>
			<PublicRoute exact path={routes.LANDING} component={LogIn} />
			<PublicRoute exact path={routes.FORM_REGISTRATION} component={RegistrationForm} />
			<PublicRoute exact path={routes.FORM_REGISTRATION_NEW} component={RegistrationFormNew} />
			<PublicRoute exact path={routes.SIGN_IN} component={LogIn} />

			<PrivateRoute exact path={routes.USERS} component={Dashboard} />
			<PrivateRoute exact path={routes.CUSTOMERS} component={Customers} />
			{/*<PrivateRoute exact path={routes.ROLES} component={RolesTable} />*/}
			{/*<PrivateRoute exact path={routes.SESSIONS} component={SessionsTable} />*/}
			<PrivateRoute exact path={routes.DASHBOARD} component={Dashboard} />
			<Route component={NoMatch}/>
		</Switch>
	);
};

export default AppRouter;
