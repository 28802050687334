export interface IMRelation {
  table: string,
  field: string,
  type: ETypes,
}

export interface IMSearchable {
  forRoles: ERoles[],
  relation: IMRelation | unknown,
}

export interface IMTable {
  visibleFor:   ERoles[],
  isSortable:   boolean,
}

export interface IMUtilDetail {
  name:         string,
  forSearch:    IMSearchable,
  type:         ETypes,
  forTable:     IMTable,
}

export interface IMUtil {
  [key: string]: IMUtilDetail,
}

export interface ITableName {
  singular:   string,
  plural:     string,
}

export enum ERoles {
  SUPER_ADMIN = 'SuperAdmin',   // Value = 100
  ADMIN       = 'Admin',        // Value = 90
  MANAGER     = 'Manager',      // Value = 70
  EDITOR      = 'Editor',       // Value = 50
  USER        = 'User',         // Value = 30
  GUEST       = 'Guest',        // Value = 10

}

export enum ETypes {
  INTEGER   = 'Integer',
  BOOLEAN   = 'Boolean',
  STRING    = 'String',
  DECIMAL   = 'Decimal',
  DATE      = 'Date',
}

export type SerializableObject = { [x: string]:
    SerializableObject | boolean | Date | number | string | []
};
