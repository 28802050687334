import jwtDecode from 'jwt-decode';
import axios, {AxiosResponse} from 'axios/index';
import * as CryptoJS  from 'crypto-js';
import {accTokenName, refTokenName, config} from '../../appConfig';
import {IRefToken, IAccToken, IResponseTokens, ICheckToken} from "./types";

const nullToken: ICheckToken = {
	userId: null,
	roleId: null,
	accToken: null,
}
const lsKey = config.site.localStorage;

export const initToken = async (): Promise<ICheckToken> => {
	// Test for server or client
	let aesAccToken: string | null;
	let aesRefToken: string | null;

	// Analysis
	try {
		aesAccToken =	!!localStorage.getItem(accTokenName()) ? localStorage.getItem(accTokenName())	: null;
		aesRefToken =	!!localStorage.getItem(refTokenName()) ? localStorage.getItem(refTokenName()) : null;
		if (!aesAccToken || !aesRefToken) {
			return nullToken;
		}
	}
	catch(error) {
		return nullToken;
	}

	const tmp  = CryptoJS.AES.decrypt(aesRefToken as string, lsKey);
	const refToken = tmp.toString(CryptoJS.enc.Utf8);
	const decodedRefToken: IAccToken = await jwtDecode(refToken);
	deleteToken();

	// RefreshToken expired
	if (isTokenExpired(decodedRefToken)) {
		return nullToken;
	}
	// RefreshToken not expired
	else {
		return await refreshToken(refToken);
	}

};


export const checkToken = async (): Promise<ICheckToken> => {
	// Test for server or client
	let aesAccToken: string | null;
	let aesRefToken: string | null;

	// Analysis
	try {
		aesAccToken =	!!localStorage.getItem(accTokenName()) ? localStorage.getItem(accTokenName())	: null;
		aesRefToken =	!!localStorage.getItem(refTokenName()) ? localStorage.getItem(refTokenName()) : null;
		if (!aesAccToken || !aesRefToken) {
			return nullToken;
		}
	}
	catch(error) {
		return nullToken;
	}

	// Token is present
	const tmp  = CryptoJS.AES.decrypt(aesAccToken as string, lsKey);
	const accToken = tmp.toString(CryptoJS.enc.Utf8);
	const decodedAccToken: IAccToken = await jwtDecode(accToken);

	// Token expired!
	if (isTokenExpired(decodedAccToken)) {

		const tmp  = CryptoJS.AES.decrypt(aesRefToken as string, lsKey);
		const refToken = tmp.toString(CryptoJS.enc.Utf8);
		const decodedRefToken: IAccToken = await jwtDecode(refToken);
		deleteToken();

		// RefreshToken expired
		if (isTokenExpired(decodedRefToken)) {
			return nullToken;
		}
		// RefreshToken not expired
		else {
			return await refreshToken(refToken);
		}
	}
	// Token is not expired
	else {
		return (
			{
				accToken: accToken,
				userId: decodedAccToken.userId,
				roleId: decodedAccToken.roleId,
			}
		)
	}
};



export const setToken = (data: IResponseTokens): void => {
	const lsKey = config.site.localStorage;
	deleteToken();
	localStorage.setItem(accTokenName(), CryptoJS.AES.encrypt(data.accessToken, lsKey).toString());
	localStorage.setItem(refTokenName(), CryptoJS.AES.encrypt(data.refreshToken, lsKey).toString());

};

export const deleteToken = (): void => {
	localStorage.removeItem(accTokenName());
	localStorage.removeItem(refTokenName());

};


// Internal

const isTokenExpired = (token: IAccToken | IRefToken): boolean => {
	if (token) {
		return token.exp < Date.now() / 1000;
	}
	return true;
};

const refreshToken = async (refToken: string): Promise<ICheckToken> => {
	try {
		const response: AxiosResponse<IResponseTokens> = await axios.post(
			`${config.site.apiURL}/api/auth/refresh`,
			{
				refreshToken: refToken,
			})
		console.log('RefreshToken: ', response);
		// Response has Token
		if (response && response.data && !!response.data.accessToken) {
			const data = response.data;
			setToken(data);
			const newAccToken: IAccToken = await jwtDecode(response.data.accessToken);
			return (
				{
					accToken: response.data.accessToken,
					userId: newAccToken.userId,
					roleId: newAccToken.roleId,
				}
			)
		}
		// N Token in response
		else {
			return nullToken;
		}
	} catch (error) {
		console.log('RefreshToken Error: ', error);
		return nullToken;
	}
}

