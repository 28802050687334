import { IUserState, EUserActionTypes } from './index';
import { Reducer } from 'redux';
import UserModel from "../../../model/userModel";

// Reducer initial State with ALL objects
export const initialState: IUserState = {
	data: new UserModel(),
	isFetching: false,
};

const reducer: Reducer<IUserState> = (state = initialState, action) => {
	switch (action.type) {
		case EUserActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case EUserActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case EUserActionTypes.POST_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.POST_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.POST_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case EUserActionTypes.PUT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.PUT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.PUT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case EUserActionTypes.DELETE_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.DELETE_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case EUserActionTypes.DELETE_FAILURE:
			return {
				...state,
				...action.payload,
			};

		// DEFAULT
		default:
			return state;
	}
};

export { reducer as UserReducer }
