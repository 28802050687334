import {ERolesActionTypes, IRolesAction, IRolesState} from './index';
import {Reducer} from 'redux';
import {IRoleDoc} from "../role";


// Reducer initial State with ALL objects
export const initialState: IRolesState = {
	data: 				[],
	isFetching: 	false,
	params: 			{},
	count:				0,
	total:				0,
	page:					0,
	pageCount:		0,
};

const reducer: Reducer<IRolesState> = (state: IRolesState = initialState, action) => {
	switch (action.type) {
		case ERolesActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ERolesActionTypes.GET_SUCCESS:
			return {
				...state,
					...action.payload,
			};
		case ERolesActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export { reducer as RolesReducer }
