import {ThunkDispatch} from "redux-thunk";
import {IApplicationState} from "../index";
import {Action} from "redux";
import {ELayoutActionTypes, ILayoutAction} from "./types";

export const actLayout = {
	init: (isMobile: boolean) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			// getState: () => IApplicationState
		) => {
			const action: ILayoutAction = {
				type: ELayoutActionTypes.INIT_STATUS,
				payload: {
					isMobile: isMobile,
					isWeb: !isMobile,
					drawerDim: isMobile ? 0 : 240,
					isDrwOpen: !isMobile,
				}
			}
			return dispatch(action);
		}
	},

	toggleOpen: () => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			getState: () => IApplicationState
		) => {
			const oldState = getState().layoutState;
			const action: ILayoutAction = {
				type: ELayoutActionTypes.TOGGLE_OPEN,
				payload: {
					isDrwOpen: !oldState.isDrwOpen,
					drawerDim: !oldState.isDrwOpen ? 240 : oldState.isWeb && !oldState.isViewSmall ? 54 : 0,
				}
			}
			return dispatch(action);
		}
	},

	toggleSize: (onlyWidth: number) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
			getState: () => IApplicationState
		) => {
			const oldState = getState().layoutState;
			let isViewSmall = false;
			let isDrwOpen = true;
			if (onlyWidth < 960) {
				isViewSmall = true;
			}
			if (oldState.isMobile || isViewSmall) {
				isDrwOpen = false
			}
			const action: ILayoutAction = {
				type: ELayoutActionTypes.TOGGLE_RESIZE,
				payload: {
					isDrwOpen,
					drawerDim: oldState.isMobile || isViewSmall ? 0 : 240,
					isViewSmall,
				}
			}
			return dispatch(action);
		}
	},

	toggleTitle: (newTitle: string) => {
		return async (
			dispatch: ThunkDispatch<IApplicationState, void, Action>,
		) => {
			const action: ILayoutAction = {
				type: ELayoutActionTypes.TOGGLE_TITLE,
				payload: {
					title: newTitle,
				}
			}
			return dispatch(action);
		}
	},

}
