import {ICustomerState, ECustomerActionTypes, IModalState} from './index';
import { Reducer } from 'redux';
import CustomerModel from "../../../model/customerModel";


// Reducer initial State with ALL objects
export const initialState: ICustomerState = {
	data: new CustomerModel(),
	isFetching: false,
};

// Reducer initial State with ALL objects
export const initialModalState: IModalState = {
	isOpen: false,
	isError: false,
};

const reducer: Reducer<ICustomerState> = (state = initialState, action) => {
	switch (action.type) {
		case ECustomerActionTypes.GET_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.GET_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.GET_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ECustomerActionTypes.POST_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.POST_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.POST_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ECustomerActionTypes.PUT_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.PUT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.PUT_FAILURE:
			return {
				...state,
				...action.payload,
			};

		case ECustomerActionTypes.DELETE_REQUEST:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.DELETE_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.DELETE_FAILURE:
			return {
				...state,
				...action.payload,
			};

		// DEFAULT
		default:
			return state;
	}
};

export { reducer as CustomerReducer }

const reducerModal: Reducer<IModalState> = (state = initialModalState, action) => {
	switch (action.type) {
		case ECustomerActionTypes.OPEN_SUCCESS_MODAL:
			return {
				...state,
				...action.payload,
			};
		case ECustomerActionTypes.OPEN_ERROR_MODAL:
			return {
				...state,
				...action.payload,
			};
		// DEFAULT
		default:
			return state;
	}
};

export { reducerModal as ModalReducer }
